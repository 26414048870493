import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { Space } from 'antd';
import { InlineDatePicker, InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import validator from 'validator';
// import 'moment/locale/th';
import api from '../commonjs/api';
import apiEndpoints from '../commonjs/apiEndpoints';
import FileUpload from '../components/FileUpload';
import InlineError from '../components/InlineError';
import { APPMAN_ERROR_CODES, OCR_ALLOWED_FILE_EXT } from '../constants';
import { errorHandler, notify, snakeToTitleCase } from '../utils/helper';
import Loading from './fairdee-x-plus/common/Loading';

const THAI_TODAY = moment(); // .locale('th').add(543, "years");
const MIN_DATE = THAI_TODAY.clone().subtract(3, 'year').toDate();
const MAX_DATE = THAI_TODAY.clone().add(3, 'year').toDate();
const USE_TYPES = [
  {
    key: 'MULTIPURPOSE_LESS_THAN_7',
    value: '110',
  },
  {
    key: 'PRIVATE_BUS_LESS_THAN_15',
    value: '220',
  },
  {
    key: 'PICKUP_TRUCK_LESS_THAN_3_TON',
    value: '320',
  },
];

const DEFAULT_DATA = {
  insurer: '',
  gross_premium: '',
  affiliate_commission: '',
  subsidy: '',
  net_premium: '',
  // sum_insured: '',
  // deductible: '',
  delivered_date: null,
  collection_date: null,
  postal_tracking_code: '',
  use_type: '',
  garage_type: '',
  insurance_class: '',
  policy_start_date: moment(),
  policy_end_date: moment().add(1, 'years'),
  compulsory_start_date: moment(),
  compulsory_end_date: moment().add(1, 'years'),
  documents: [],
  policy_number: '',
  postal_tracking_code: '',
  dispatch_date: null,
  waiting_for_physical_policy: false,
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: '20px',
    width: '100%',
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  formLabel: {
    margin: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  fullWidth: {
    width: '100%',
  },
  datePicker: {
    width: '100%',
    marginBottom: '24px',
  },
});

class PolicyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: DEFAULT_DATA,
      insurers: [],
      requestSuccess: null,
      errors: {},
      updatedData: {},
      aiLoader: false,
      warnings: {},
      messages: {},
    };
  }

  init = (props) => {
    if (props.saleTrack) {
      let data = { ...DEFAULT_DATA, ...props.policyData };
      data.compulsory_start_date = props.quotation?.compulsory_start_date;
      const date = new Date(data.compulsory_start_date);
      date.setFullYear(date.getFullYear() + 1);
      data.compulsory_end_date = date.toISOString().split('T')[0];
      this.setState(
        {
          data,
        },
        () => {}
      );
    } else {
      let data = { ...this.state.data, ...props.policyData };
      data.policy_start_date = this.state.data.policy_start_date;
      const date = new Date(data.policy_start_date);
      date.setFullYear(date.getFullYear() + 1);
      data.policy_end_date = date.toISOString().split('T')[0];
      data.compulsory_start_date = props.quotation?.compulsory_start_date || this.state.data.compulsory_start_date;
      this.setState(
        {
          data: data,
        },
        () => {}
      );
    }
  };

  componentDidMount() {
    this.getInsurers();
    this.init(this.props);
    if (this.props.carId) {
      this.getPolicy(this.props.carId);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props !== nextProps) {
      if (this.props.policyData !== nextProps.policyData) {
        this.init(nextProps);
      }
      if (this.props.is_compulsory !== nextProps.is_compulsory) {
        this.setState({
          warnings: {},
          messages: {},
        });
      }
      if (this.props.carId) {
        this.getPolicy(this.props.carId);
      }
    }
  };

  getPolicy = (id) => {
    api.crm
      .getFairdeePolicy({ car: id })
      .then((response) => {
        if (response.results.length) {
          response.results.forEach((data) => {
            if (data.is_compulsory === this.props.is_compulsory) {
              data.documents = data.documents.map((d) => {
                d.name = d.file.original_file_name;
                delete d.file;
                return d;
              });
              this.init({
                policyData: data,
              });
            }
          });
        }
      })
      .catch((_) => {
        this.setState({
          loading: false,
        });
      });
  };

  getInsurers = () => {
    api.masterData
      .getInsurers()
      .then((resp) => {
        let insurersList = resp.results;

        resp.results.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        this.setState({
          insurers: insurersList,
        });
      })
      .catch((error) => errorHandler(error.response, true));
  };

  handleChange = (name) => (event) => {
    let value = null;
    if (moment.isMoment(event)) {
      if (name === 'dispatch_date' || name === 'delivered_date' || name === 'collection_date') {
        value = event.format('YYYY-MM-DD HH:mm:ss');
      } else {
        value = event;
      }
      if (name === 'policy_start_date') {
        this.setState({
          data: { ...this.state.data, [name]: value, policy_end_date: event.clone().add('years', 1) },
          updatedData: { ...this.state.updatedData, [name]: value, policy_end_date: event.clone().add('years', 1) },
        });
        return;
      }
      if (name === 'compulsory_start_date') {
        this.setState({
          data: { ...this.state.data, [name]: value, compulsory_end_date: event.clone().add('years', 1) },
          updatedData: { ...this.state.updatedData, [name]: value, compulsory_end_date: event.clone().add('years', 1) },
        });
        return;
      }
    } else {
      value = event.target.value;

      if (event.target.type === 'number') {
        let decimalCount = (event.target.value + '.').split('.')[1].length;
        if (decimalCount > 2) {
          return;
        }
      }
    }
    let obj = {};
    if (name === 'gross_premium') {
      let gross_premium = parseFloat(event.target.value || 0, 10);
      // let netPremium = gross_premium * 0.9309;
      let netPremium = gross_premium;
      let duty = netPremium * 0.004;
      let tax = (netPremium + duty) * 0.07;
      let premium_after_tax = gross_premium + duty + tax;

      obj.gross_premium = gross_premium || '';
      obj.tax = tax ? tax.toFixed(2) : '';
      obj.duty = duty ? duty.toFixed(2) : '';
      obj.premium_after_tax = premium_after_tax ? premium_after_tax.toFixed(2) : '';
    }
    this.setState({
      data: { ...this.state.data, [name]: value, ...obj },
      updatedData: { ...this.state.updatedData, [name]: value, ...obj },
    });
  };

  handleSwitchToggle = (name) => (event) => {
    const data = {
      ...this.state.data,
      [name]: event.target.checked,
    };
    this.setState({
      data: data,
      updatedData: { ...this.state.updatedData, [name]: event.target.checked },
    });
  };

  uploadOCR = async (params, type, file) => {
    // fileListLoading used for show the re-upload file
    const languageMapError = this.props.languageMap.messages;
    this.setState({
      aiLoader: true,
    });
    api.utils
      .uploadOcr(params)
      .then((resp) => {
        // getting ocr object data

        if (Object.keys(resp.vendor_response).some((r) => APPMAN_ERROR_CODES.includes(r))) {
          notify(languageMapError[`Image failed to meet OCR requirements, please try again`], 'error');
        } else {
          if (Object.keys(resp.vendor_response).length === 1 && resp.vendor_response?.document_type) {
            params.append('return_only_document_type', false);
            params.append('file_type', resp.vendor_response?.document_type);
            this.uploadOCR(params);
          } else if (Object.keys(resp.vendor_response).length > 1 && resp.vendor_response?.document_type) {
            let obj = {
                policy_number: resp.vendor_response?.policy_number,
                policy_end_date: resp.vendor_response?.policy_end_date,
                policy_start_date: resp.vendor_response?.policy_start_date,
                type_of_policy: resp.vendor_response.type_of_policy,
              },
              warnings = {},
              messages = {};
            if (resp.vendor_response.type_of_policy === 'voluntary_insurance' && this.props.is_compulsory) {
              warnings.invalid_document = `${resp.vendor_response.type_of_policy} policy uploaded`;
            }
            if (this.props.quotation?.vehicle_number !== resp.vendor_response.vehicle_number) {
              warnings.vehicle_number = `${resp.vendor_response.vehicle_number} might not match for the sale`;
            }
            if (
              this.props.quotation?.price_list?.insurer?.code?.toUpperCase() !==
              resp.vendor_response?.vehicle_number?.toUpperCase()
            ) {
              messages.vehicle_number = `Policy uploaded for ${resp.vendor_response.insurer_code} -  ${resp.vendor_response.insurer_name} `;
            }
            this.setState({
              warnings: { ...this.state.warnings, ...warnings },
              messages: { ...this.state.messages, ...messages },
            });

            this.setState({
              data: { ...this.state.data, ...obj },
              aiLoader: false,
            });
          }
        }
      })
      .catch((error) => {
        // handling error from our server
        errorHandler(error.response, true);
        this.setState({
          aiLoader: false,
        });
      });
  };

  onOcrUpload = (file, name) => {
    if (OCR_ALLOWED_FILE_EXT.includes(file.type)) {
      let params = new FormData();
      params.append('file', file);
      params.append('return_only_document_type', true);
      params.append('file_type', 'unknown');
      params.append('vendor_name', 'fairdee_ai');
      params.append('regenerate_response', true);

      this.uploadOCR(params, name, file);
    } else {
      notify(this.props.languageMap.components.makeModel.OcrFailedReq, 'error');
    }
  };

  fileUploaded = (obj) => {
    // obj.new = true;
    let data = this.state.data;
    let updatedData = this.state.updatedData;
    data.documents = [...this.state.data.documents, obj];
    if (!updatedData.documents) {
      updatedData.documents = [];
    }
    updatedData.documents = [...this.state.updatedData.documents, obj];
    this.onOcrUpload(obj);
    this.setState(
      {
        data,
        updatedData,
      },
      () => {}
    );
  };

  deleteDocumentApi = (id) => {
    api.crm
      .deleteDocument(id)
      .then((response) => {
        notify('Deleted successfully', 'success');
      })
      .catch((error) => {
        errorHandler(error.response);
      });
  };

  deleteDocument = (obj) => {
    let data = this.state.data;
    let updatedData = this.state.updatedData;
    data.documents = data.documents.filter((data, index) => index !== obj.index);
    if (!updatedData.documents) {
      updatedData.documents = [];
    }
    updatedData.documents = updatedData.documents.filter((data, index) => index !== obj.index);

    if (obj.id) {
      this.deleteDocumentApi(obj.id);
      data.documents = data.documents.filter((data) => data.id !== obj.id);
      if (!updatedData.documents) {
        updatedData.documents = [];
      }
      updatedData.documents = updatedData.documents.filter((data) => data.id !== obj.id);
    }
    this.setState({
      data,
      updatedData,
    });
  };

  validatePolicyDetails = (data) => {
    let errors = {},
      messages = this.props.languageMap.messages;

    if ('gross_premium' in data && data.gross_premium && !validator.isDecimal(data.gross_premium.toString())) {
      errors.gross_premium = 'Value should be numeric';
    }

    if (
      'affiliate_commission' in data &&
      data.affiliate_commission &&
      !validator.isDecimal(data.affiliate_commission.toString())
    ) {
      errors.affiliate_commission = 'Value should be numeric';
    }

    if ('subsidy' in data && data.subsidy && !validator.isDecimal(data.subsidy.toString())) {
      errors.subsidy = 'Value should be numeric';
    }

    if ('net_premium' in data && data.net_premium && !validator.isDecimal(data.net_premium.toString())) {
      errors.net_premium = 'Value should be numeric';
    }

    if (('documents' in data && !data.documents.length) ||
      (!this.state.data.documents || !this.state.data.documents.length)) {
      errors.policy = messages.uploadPolicy || 'Please upload policy file';
    }

    // Policy number validation
    if ('policy_number' in data && !data.policy_number) {
      errors.policy_number = 'Required Field';
    }

    // Status update validation
    if (this.props.statusUpdate && !this.props.is_compulsory) {
      if (!this.state.data.postal_tracking_code && this.props.policyStatus === 'policy_sent_to_user') {
        errors.postal_tracking_code = 'Required Field';
      }
      if (!this.state.data.dispatch_date && !this.props.adminAction) {
        errors.dispatch_date = 'Required Field';
      }
    }

    return errors;
  };

  generatePolicyParameters = (data) => {
    let params = {};

    if (data.policy_start_date) {
      params.policy_start_date = moment(data.policy_start_date).format('YYYY-MM-DD');
    }
    if (data.policy_end_date) {
      params.policy_end_date = moment(data.policy_end_date).format('YYYY-MM-DD');
    }
    if (data.compulsory_start_date) {
      params.compulsory_start_date = moment(data.compulsory_start_date).format('YYYY-MM-DD');
    }
    if (data.compulsory_end_date) {
      params.compulsory_end_date = moment(data.compulsory_end_date).format('YYYY-MM-DD');
    }
    if (data.dispatch_date) {
      params.dispatch_date = moment(data.policy_end_date).format('YYYY-MM-DD HH:mm:ss');
    }

    if (data.insurer) {
      params.insurer = data.insurer;
    }
    if (data.gross_premium) {
      params.gross_premium = parseFloat(data.gross_premium, 10).toFixed(2);
    }
    if (data.is_compulsory) {
      params.is_compulsory = parseFloat(data.is_compulsory, 10).toFixed(2);
    }
    if (data.affiliate_commission) {
      params.affiliate_commission = parseFloat(data.affiliate_commission, 10).toFixed(2);
    }
    if (data.subsidy) {
      params.subsidy = parseFloat(data.subsidy, 10).toFixed(2);
    }
    if (data.net_premium) {
      params.net_premium = parseFloat(data.net_premium, 10).toFixed(2);
    }
    if (data.policy_number) {
      params.policy_number = data.policy_number;
    }
    if (data.duty) {
      params.duty = parseFloat(data.duty).toFixed(2);
    }
    if (data.tax) {
      params.tax = parseFloat(data.tax).toFixed(2);
    }
    if (data.waiting_for_physical_policy !== undefined) {
      params.waiting_for_physical_policy = data.waiting_for_physical_policy;
    }

    if (data.postal_tracking_code) {
      params.postal_tracking_code = data.postal_tracking_code;
    }
    if (this.props.is_compulsory) {
      return params;
    }
    if (data.use_type) {
      params.use_type = data.use_type;
    }
    if (data.garage_type) {
      params.garage_type = data.garage_type;
    }
    if (data.insurance_class) {
      params.insurance_class = data.insurance_class;
    }
    return params;
  };

  patchPolicyData = () => {
    let errors = this.validatePolicyDetails(this.state.updatedData);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    let data = Object.assign({}, this.state.updatedData);
    let params = this.generatePolicyParameters(data);
    if (!Object.keys(params).length) {
      if (data.documents && data.documents.length) {
        this.patchPolicyFile(this.state.data.id, data.documents[0]);
      }
      if (this.props.quotation.needs_compulsory_insurance && !this.props.is_compulsory) {
        this.setState({
          loading: false,
        });
        this.props.showCompulsaryPolicy();
      } else {
        this.props.action();
      }
      return;
    }
    this.setState({
      loading: true,
    });
    api.crm
      .patchFairdeePolicy(this.state.data.id, params)
      .then((response) => {
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.state.updatedData.documents && this.state.updatedData.documents.length) {
          this.patchPolicyFile(this.state.data.id, this.state.updatedData.documents[0]);
        }
        if (this.props.quotation.needs_compulsory_insurance && !this.props.is_compulsory) {
          this.props.showCompulsaryPolicy();
        } else {
          this.props.action(true);
        }
        if (this.props.statusUpdate) {
          this.props.statusUpdate(response);
        }
        notify('Update successfully', 'success');
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        let errors = errorHandler(error.response);
        this.setState({
          errors,
        });
      });
  };

  patchPolicyFile = (id, file) => {
    let params = {
      file,
      policy_id: parseInt(id, 10),
      product: 'fairdeepolicy',
    };
    let FD = new FormData();
    Object.keys(params).forEach((key) => {
      FD.append(key, params[key]);
    });
    api.crm
      .policyDocument(FD)
      .then((response) => {
        this.setState({
          updatedData: { ...this.state.updatedData, documents: [] },
        });
      })
      .catch((error) => {
        errorHandler(error.response);
      });
  };

  savePolicyData = () => {
    let errors = this.validatePolicyDetails(this.state.data);
    this.setState({
      errors,
    });
    if (Object.keys(errors).length) {
      return;
    }
    let data = Object.assign({}, this.state.data);
    let params = this.generatePolicyParameters(data);
    params.is_compulsory = this.props.is_compulsory;
    params.car = this.props.carId || null;
    if (!this.props.is_compulsory) {
      params.total_premium = params.net_premium;
    }

    if (!params.car) {
      return;
    }
    this.setState({
      loading: true,
    });
    api.crm
      .fairdeePolicy(params)
      .then((response) => {
        if (this.props.setPolicies) {
          this.props.setPolicies(this.props.is_compulsory ? 'compulsary' : 'voluntary', response.id);
        }
        let fieldName = this.props.is_compulsory ? 'compulsory_policy' : 'voluntary_policy';

        if (this.state.updatedData.documents && this.state.updatedData.documents.length) {
          this.patchPolicyFile(response.id, this.state.updatedData.documents[0]);
        }

        return api.crm.patchSaletrack(this.props.saleTrack.id, { [fieldName]: response.id });
      })
      .then((response) => {
        this.setState({
          loading: false,
          requestSuccess: true,
        });
        if (this.props.statusUpdate) {
          this.props.statusUpdate(response);
        }
        if (this.props.quotation.needs_compulsory_insurance && !this.props.is_compulsory) {
          this.props.showCompulsaryPolicy();
        } else {
          this.props.action(true);
        }
        notify('Added successfully', 'success');
      })
      .catch((error) => {
        this.setState({
          loading: false,
          requestSuccess: false,
        });
        let errors = errorHandler(error.response, true);
        this.setState({
          errors,
        });
      });
  };

  saveData = () => {
    if (this.state.data.id) {
      if (!Object.keys(this.state.updatedData).length && this.state.data.documents.length && !this.props.statusUpdate) {
        if (this.props.quotation.needs_compulsory_insurance && !this.props.is_compulsory) {
          this.changeStep(1);
        } else {
          this.props.action();
        }
        return;
      }
      this.patchPolicyData();
    } else {
      this.savePolicyData();
    }
  };

  changeStep = (step) => {
    if (this.props.changeStep) {
      this.props.changeStep(step);
    }
  };

  renderWarningInfo = () => {
    let { warnings } = this.state;
    if (Object.keys(warnings).length) {
      return (
        <div className="warning-wrapper">
          <div className="icon-wrap">
            <i className="fa-solid fa-triangle-exclamation"></i>
          </div>
          <div>
            {Object.keys(warnings).map((key, index) => {
              return <p key={index}>{snakeToTitleCase(warnings[key])}</p>;
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  renderMessageInfo = () => {
    let { messages } = this.state;
    if (Object.keys(messages).length) {
      return (
        <div className="message-wrapper">
          {Object.keys(messages).map((key, index) => {
            return <p key={index}>{messages[key]}</p>;
          })}
        </div>
      );
    }
    return null;
  };

  render() {
    let { data, errors, loading, insurers } = this.state;
    const { classes } = this.props;
    const languageMap = this.props.languageMap.components.policyForm;

    return (
      <form className={classes.container} noValidate autoComplete="nope">
        <Space direction="vertical">
          {insurers.length && (
            <TextField
              select
              id="insurer"
              label={languageMap.textOne}
              className={classes.textField}
              value={data.insurer ? data.insurer.id || data.insurer : ''}
              margin="normal"
              error={errors.insurer ? true : false}
              helperText={errors.insurer}
              autoComplete="off"
              name="insurer"
              onChange={this.handleChange('insurer')}
            >
              {insurers.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <div>
            <div className="flex-item flex-row-center">
              <FileUpload
                download={true}
                files={data.documents || []}
                name="policy"
                onFileUpload={this.fileUploaded}
                onFileRemove={this.deleteDocument}
                downloadPath={apiEndpoints.policyDocument}
                limit={1}
                btnText={languageMap.textTwentyFour}
                error={errors.policy ? errors.policy : ''}
              />
              {this.state.aiLoader ? <Loading type="ailoader" /> : null}
            </div>
          </div>

          {this.renderMessageInfo()}
          {this.renderWarningInfo()}
          {!this.props.is_compulsory && (
            <div className={classes.fullWidth}>
              {!this.props.adminAction && (
                <TextField
                  select
                  label={languageMap.textFourteen}
                  className={classes.textField + ' ' + classes.inline}
                  value={data.use_type || ''}
                  error={Boolean(errors.use_type)}
                  helperText={errors.use_type}
                  autoComplete="off"
                  onChange={this.handleChange('use_type')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  {USE_TYPES.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {snakeToTitleCase(type.key)} ({type.value})
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <div className={classes.fullWidth}>
                <InputLabel htmlFor="">{languageMap.textFifteen}</InputLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.garage_type === '0'}
                        onChange={this.handleChange('garage_type')}
                        value="0"
                        name="garage_type"
                      />
                    }
                    label={languageMap.textSixteen}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.garage_type === '1'}
                        onChange={this.handleChange('garage_type')}
                        value="1"
                        name="garage_type"
                      />
                    }
                    label={languageMap.textSeventeen}
                  />
                </FormGroup>
              </div>
              <div className={classes.fullWidth}>
                <InputLabel htmlFor="">{languageMap.textEighteen}</InputLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '1'}
                        onChange={this.handleChange('insurance_class')}
                        value="1"
                        name="insurance_class"
                      />
                    }
                    label="1"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '2+'}
                        onChange={this.handleChange('insurance_class')}
                        value="2+"
                        name="insurance_class"
                        aria-label="2+"
                      />
                    }
                    label="2+"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '3+'}
                        onChange={this.handleChange('insurance_class')}
                        value="3+"
                        name="insurance_class"
                        aria-label="3+"
                      />
                    }
                    label="3+"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '1 ราคาถูกพิเศษ'}
                        onChange={this.handleChange('insurance_class')}
                        value="1 ราคาถูกพิเศษ"
                        name="insurance_class"
                        aria-label="1 ราคาถูกพิเศษ"
                      />
                    }
                    label="1 ราคาถูกพิเศษ"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '2+ ราคาถูกพิเศษ'}
                        onChange={this.handleChange('insurance_class')}
                        value="2+ ราคาถูกพิเศษ"
                        name="insurance_class"
                        aria-label="2+ ราคาถูกพิเศษ"
                      />
                    }
                    label="2+ ราคาถูกพิเศษ"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={data.insurance_class === '3+ ราคาถูกพิเศษ'}
                        onChange={this.handleChange('insurance_class')}
                        value="3+ ราคาถูกพิเศษ"
                        name="insurance_class"
                        aria-label="3+ ราคาถูกพิเศษ"
                      />
                    }
                    label="3+ ราคาถูกพิเศษ"
                  />
                </FormGroup>
              </div>
            </div>
          )}
          {!this.props.adminAction && (
            <>
              <TextField
                type="number"
                id="gross_premium"
                label={languageMap.textTwo}
                className={classes.textField}
                onChange={this.handleChange('gross_premium')}
                name="gross_premium"
                error={errors.gross_premium ? true : false}
                helperText={errors.gross_premium}
                autoComplete="off"
                value={data.gross_premium || ''}
              />
              <TextField
                type="number"
                id="affiliate_commission"
                label={languageMap.textThree}
                className={classes.textField}
                value={data.affiliate_commission || ''}
                margin="normal"
                error={errors.affiliate_commission ? true : false}
                helperText={errors.affiliate_commission}
                autoComplete="off"
                onChange={this.handleChange('affiliate_commission')}
              />
              <TextField
                type="number"
                id="subsidy"
                label={languageMap.textFour}
                className={classes.textField}
                value={data.subsidy || ''}
                margin="normal"
                error={errors.subsidy ? true : false}
                helperText={errors.subsidy}
                autoComplete="off"
                onChange={this.handleChange('subsidy')}
              />
              {!this.props.is_compulsory && (
                <div>
                  <TextField
                    type="number"
                    id="tax"
                    label={languageMap.textTwentyOne}
                    value={data.tax || ''}
                    margin="normal"
                    error={errors.tax ? true : false}
                    helperText={errors.tax}
                    autoComplete="off"
                    name="tax"
                    onChange={this.handleChange('tax')}
                    className="full-width"
                  />
                  <TextField
                    type="number"
                    id="duty"
                    label={languageMap.textTwentyTwo}
                    value={data.duty || ''}
                    margin="normal"
                    error={errors.duty ? true : false}
                    helperText={errors.duty}
                    autoComplete="off"
                    name="duty"
                    onChange={this.handleChange('duty')}
                    className="full-width"
                  />
                  <TextField
                    type="number"
                    id="net_premium"
                    label={languageMap.textFive}
                    className={classes.textField}
                    value={data.net_premium || ''}
                    margin="normal"
                    error={errors.net_premium ? true : false}
                    helperText={errors.net_premium}
                    autoComplete="off"
                    onChange={this.handleChange('net_premium')}
                  />
                </div>
              )}
            </>
          )}
          <TextField
            type="text"
            id="policy_number"
            label={languageMap.textTwentyThree}
            className={classes.textField}
            value={data.policy_number || ''}
            margin="normal"
            error={errors.policy_number ? true : false}
            helperText={errors.policy_number}
            autoComplete="off"
            onChange={this.handleChange('policy_number')}
          />
          <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
            <InlineDatePicker
              // keyboard
              id={this.props.is_compulsory ? 'compulsory_start_date' : 'policy_start_date'}
              label={languageMap.textEight}
              initialFocusedDate={moment()}
              className={classes.datePicker}
              value={this.props.is_compulsory ? data.compulsory_start_date : data.policy_start_date}
              onChange={
                this.props.is_compulsory
                  ? this.handleChange('compulsory_start_date')
                  : this.handleChange('policy_start_date')
              }
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
            <InlineDatePicker
              // keyboard
              id={this.props.is_compulsory ? 'compulsory_end_date' : 'policy_end_date'}
              label={languageMap.textNine}
              initialFocusedDate={moment().add(1, 'year')}
              className={classes.datePicker}
              value={this.props.is_compulsory ? data.compulsory_end_date : data.policy_end_date}
              onChange={
                this.props.is_compulsory
                  ? this.handleChange('compulsory_end_date')
                  : this.handleChange('policy_end_date')
              }
              format="DD/MM/YYYY"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          </MuiPickersUtilsProvider>
          {!this.props.is_compulsory && !this.props.adminAction && (
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="th" moment={moment}>
                <InlineDateTimePicker
                  // keyboard
                  id="dispatch_date"
                  label={languageMap.textTen}
                  initialFocusedDate={moment()}
                  className={classes.datePicker}
                  value={data.dispatch_date}
                  onChange={this.handleChange('dispatch_date')}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  format="DD/MM/YYYY hh:mm A"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  error={errors.dispatch_date ? true : false}
                  helperText={errors.dispatch_date}
                />
                <InlineDateTimePicker
                  // keyboard
                  id="delivered_date"
                  label={languageMap.textEleven}
                  initialFocusedDate={moment()}
                  className={classes.datePicker}
                  value={data.delivered_date}
                  onChange={this.handleChange('delivered_date')}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  format="DD/MM/YYYY hh:mm A"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  error={errors.delivered_date ? true : false}
                  helperText={errors.delivered_date}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
          <div>
            <TextField
              id="postal_tracking_code"
              label={languageMap.textThirteen}
              className={classes.textField}
              value={data.postal_tracking_code || ''}
              margin="normal"
              error={errors.postal_tracking_code ? true : false}
              helperText={errors.postal_tracking_code}
              autoComplete="off"
              onChange={this.handleChange('postal_tracking_code')}
            />
          </div>
          <div>
            <p className="label-text">{languageMap['Are you waiting for Physical Policy?']}</p>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.data.waiting_for_physical_policy}
                  onChange={this.handleSwitchToggle('waiting_for_physical_policy')}
                  value="checkedB"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={this.state.data.waiting_for_physical_policy ? languageMap['Yes'] : languageMap['No']}
            />
          </div>
        </Space>
        <div className="buttons-wrap">
          <div>
            <Button onClick={this.changeStep.bind(null, 0)}>{languageMap.textNineteen}</Button>
          </div>
          <div>
            {loading ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Button variant="contained" size="small" color="primary" onClick={this.saveData}>
                {languageMap.textTwenty}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

PolicyForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    insurers: state.insurers,
    languageMap: state.languageMap,
  };
}

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(PolicyForm)));
