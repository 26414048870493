import { S3_URL } from '../constants/config';
import AioiCOA from '../images/coa-mapping/AOI.png';
import AsiaCOA from '../images/coa-mapping/ASIA-COA.jpg';
import AxaCOA from '../images/coa-mapping/AXA-COA.jpg';
import BkiCOA from '../images/coa-mapping/BKI-COA.jpg';
import DevCOA from '../images/coa-mapping/DEV-COA.jpg';
import LmgCOA from '../images/coa-mapping/LMG-COA.jpg';
import MtiCOA from '../images/coa-mapping/MTI-COA.jpg';
import NsiCOA from '../images/coa-mapping/NSI-COA.png';
import SeiCOA from '../images/coa-mapping/SEI-COA.jpg';
import SmkCOA from '../images/coa-mapping/SMK-COA.jpg';
import TipCOA from '../images/coa-mapping/TIP-COA.jpg';
import TmthCOA from '../images/coa-mapping/TMTH-COA.jpg';
import TniCOA from '../images/coa-mapping/TNI-COA.jpg';
import TviCOA from '../images/coa-mapping/TVI-COA.jpg';
import VibCOA from '../images/coa-mapping/VIB-COA.jpg';

function dropdownYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  var startYear = 1973;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  years.sort((a, b) => b - a);
  const finalArr = years.reduce((prev, curr) => {
    prev.push({ key: curr, text: curr, value: curr, name: curr });
    return prev;
  }, []);
  return finalArr;
}

const monthsDropDown = [
  { key: '1', value: '1', text: 'January' },
  { key: '2', value: '2', text: 'February' },
  { key: '3', value: '3', text: 'March' },
  { key: '4', value: '4', text: 'April' },
  { key: '5', value: '5', text: 'May' },
  { key: '6', value: '6', text: 'June' },
  { key: '7', value: '7', text: 'July' },
  { key: '8', value: '8', text: 'August' },
  { key: '9', value: '9', text: 'September' },
  { key: '10', value: '10', text: 'October' },
  { key: '11', value: '11', text: 'November' },
  { key: '12', value: '12', text: 'December' },
];

const driverAgeGroup = [
  { key: '18-24', value: '18-24', text: '18-24' },
  { key: '25-35', value: '25-35', text: '25-35' },
  { key: '36-50', value: '36-50', text: '36-50' },
  { key: 'Greater than 50', value: '>50', text: 'Greater than 50' },
];

const policyStatusOptions = [
  { key: 'order_received', value: 'order_received', text: 'Order Received' },
  { key: 'covernote_issued', value: 'covernote_issued', text: 'Covernote Issued' },
  { key: 'policy_uploaded', value: 'policy_uploaded', text: 'Policy Uploaded' },
  { key: 'docs_rejected', value: 'docs_rejected', text: 'Docs Rejected' },
  { key: 'policy_issued', value: 'policy_issued', text: 'Policy Issued' },
  { key: 'policy_sent_to_user', value: 'policy_sent_to_user', text: 'Policy sent to user' },
];

const paymentStatusOptions = [
  { key: 'invoice_issued', value: 'invoice_issued', text: 'Invoice Issued' },
  { key: 'payment_proof_received', value: 'payment_proof_received', text: 'Payment Proof Received' },
  { key: 'payment_verified', value: 'payment_verified', text: 'Payment Verified' },
  { key: 'credit_approved', value: 'credit_approved', text: 'Credit Approved' },
  { key: 'commission_transferred', value: 'commission_transferred', text: 'Commission Transferred' },
];

const insurersMap = {
  4: 'Bangkok Insurance',
  5: 'Thaivivat Insurance',
  6: 'MSIG Insurance',
  7: 'Tokio Marine',
  8: 'Dhipaya Insurance',
};

const filterMap = {
  max_down_payment: 'Maximum Down Payment',
  min_down_payment: 'Minimum Down Payment',
  max_suminsured: 'Maximum Sum Insured',
  min_suminsured: 'Minimum Sum Insured',
  max_deductible: 'Maximum Deductible',
  min_deductible: 'Minimum Deductible',
  max_third_party: 'Maximum Third Party Property',
  min_third_party: 'Minimum Third Party Property',
  sum_insured: 'Sum Insured: Low - High',
  '-sum_insured': 'Sum Insured: High - Low',
  deductible: 'Deductible: Low - High',
  '-deductible': 'Deductible: High - Low',
  premium_after_tax: 'Premium After Tax: Low - High',
  '-premium_after_tax': 'Premium After Tax: High - Low',
  province: 'Province',
};

const garageMap = {
  0: 'Garage',
  1: 'Dealership',
};

const sortMap = [
  { key: 'best_of_all', value: 'best_of_all', text: 'ดีที่สุด' },
  { key: 'sum_insured_low_to_high', value: 'sum_insured', text: 'ทุนประกัน: ต่ำไปสูง' },
  { key: 'sum_insured_high_to_low', value: '-sum_insured', text: 'ทุนประกัน: สูงไปต่ำ' },
  { key: 'deductible_low_to_high', value: 'deductible', text: 'ค่าเสียหายส่วนแรก: ต่ำไปสูง' },
  { key: 'deductible_high_to_low', value: '-deductible', text: 'ค่าเสียหายส่วนแรก: สูงไปต่ำ' },
  { key: 'premium_after_tax_low_to_high', value: 'premium_after_tax', text: 'ราคา: ต่ำไปสูง' },
  { key: 'premium_after_tax_high_to_low', value: '-premium_after_tax', text: 'ราคา: สูงไปต่ำ' },
];

const sortMapEnglish = [
  { key: 'best_of_all', value: 'best_of_all', text: 'Best of all' },
  { key: 'sum_insured_low_to_high', value: 'sum_insured', text: 'Sum insured: low to high' },
  { key: 'sum_insured_high_to_low', value: '-sum_insured', text: 'Sum insured: high to low' },
  { key: 'deductible_low_to_high', value: 'deductible', text: 'Deductible: low to high' },
  { key: 'deductible_high_to_low', value: '-deductible', text: 'Deductible: high to low' },
  { key: 'premium_after_tax_low_to_high', value: 'premium_after_tax', text: 'Price: low to high' },
  { key: 'premium_after_tax_high_to_low', value: '-premium_after_tax', text: 'Price: high to low' },
];

const defaultFilterData = () => {
  return {
    max_down_payment: 12,
    min_down_payment: 0,
    max_suminsured: null,
    min_suminsured: null,
    max_deductible: 5000,
    min_deductible: 0,
    max_third_party: 3000000,
    min_third_party: 0,
    insurer: [],
    garage_type: [],
    insurance_class: [],
    flood_protection: [],
    camera_discount_eligible: [],
    driver_discount_eligible: [],
    province: '',
    bikeFilter: {
      insurance_class: [],
      is_commercial: [],
      insurer: [],
    },
  };
};

const banks = [
  { key: '1', value: '1', text: 'Bank 1' },
  { key: '2', value: '2', text: 'Bank 2' },
  { key: '3', value: '3', text: 'Bank 3' },
  { key: '4', value: '4', text: 'Bank 4' },
  { key: '5', value: '5', text: 'Bank 5' },
  { key: '6', value: '6', text: 'Bank 6' },
  { key: '7', value: '7', text: 'Bank 7' },
  { key: '8', value: '8', text: 'Bank 8' },
  { key: '9', value: '9', text: 'Bank 9' },
  { key: '10', value: '10', text: 'Bank 10' },
];

const genders = [
  { key: 'M', value: 'M', text: 'Male' },
  { key: 'F', value: 'F', text: 'Female' },
];

const insurerInfoMap = {
  'Deves Insurance': {
    dealershipGarage: 'https://www.deves.co.th/th/customer-service/garage/',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.deves.co.th/th/customer-service/garage/',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.deves.co.th/th/customer-service/hospital/',
    number_of_hospitals: '',
    branches_list: 'https://www.deves.co.th/th/customer-service/branch/',
    number_of_branches: '',
    agentCode: '',
  },
  'LMG Insurance': {
    dealershipGarage: 'https://www.lmginsurance.co.th/th/Pages/Search.aspx',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.lmginsurance.co.th/th/Pages/Search.aspx',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.lmginsurance.co.th/th/Pages/Search.aspx',
    number_of_hospitals: '',
    branches_list: 'https://www.lmginsurance.co.th/th/Pages/Search.aspx',
    number_of_branches: '',
    agentCode: '',
  },
  'Thaisri Insurance': {
    dealershipGarage: 'https://www.thaisri.com/services/garage-list/',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.thaisri.com/services/garage-list/',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.thaisri.com/services/medical-network/',
    number_of_hospitals: '',
    branches_list: 'https://www.thaisri.com/contact-us/branches/',
    number_of_branches: '',
    agentCode: '',
  },
  'SouthEast Insurance': {
    dealershipGarage: 'http://www.segroup.co.th/seic/service_new_garage.php',
    dealershipGarageNumber: '',
    authorizedgarage: 'http://www.segroup.co.th/seic/service_new_garage.php',
    authorizedgarageNumber: '',
    hospitals_list: 'http://www.segroup.co.th/seic/service_new_hospital.php',
    number_of_hospitals: '',
    branches_list: 'https://www.seic.co.th/services/search-branch',
    number_of_branches: '',
    agentCode: '',
  },
  'Thaipaiboon Insurance': {
    dealershipGarage: 'https://www.thaipaiboon.com/main.php?m=corporate&p=mod_supportservice&cid=3',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.thaipaiboon.com/main.php?m=corporate&p=mod_supportservice&cid=3',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.thaipaiboon.com/main.phpm=corporate&p=mod_supportservice&cid=1',
    number_of_hospitals: '',
    branches_list: 'https://www.thaipaiboon.com/main.php?m=contact',
    number_of_branches: '',
    agentCode: '',
  },
  'Thanachart Insurance': {
    dealershipGarage: 'https://www.thanachartinsurance.co.th/tnifrontend/tnigarage.aspx?default=1',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.thanachartinsurance.co.th/tnifrontend/tnigarage.aspx?default=1',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.thanachartinsurance.co.th/tnifrontend/tnihospital.aspx',
    number_of_hospitals: '',
    branches_list: 'https://www.thanachartinsurance.co.th/tnifrontend/tnicontactus.aspx',
    number_of_branches: '',
    agentCode: '',
  },
  'AXA Insurance': {
    dealershipGarage: 'https://www.axa.co.th/th/garage-locator',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.axa.co.th/th/garage-locator',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.axa.co.th/th/hospital-locator',
    number_of_hospitals: '',
    branches_list: 'https://www.axa.co.th/about-axa-thailand',
    number_of_branches: '',
    agentCode: '',
  },
  'Bangkok Insurance': {
    dealershipGarage: 'https://www.bangkokinsurance.com/online/pdf/pdfgarage.php?type=2',
    dealershipGarageNumber: '253',
    authorizedgarage: 'https://www.bangkokinsurance.com/online/pdf/pdfgarage.php',
    authorizedgarageNumber: '341',
    hospitals_list: 'https://www.bangkokinsurance.com/claim/hospitals',
    number_of_hospitals: '225',
    branches_list: 'https://www.bangkokinsurance.com/contact/branch',
    number_of_branches: '64',
    agentCode: '651700',
  },
  'Thaivivat Insurance': {
    dealershipGarage: 'https://www.thaivivat.co.th/th/service.php',
    dealershipGarageNumber: '155',
    authorizedgarage: 'https://www.thaivivat.co.th/th/service.php',
    authorizedgarageNumber: '1044',
    hospitals_list: 'https://www.thaivivat.co.th/th/service_hospital.php',
    number_of_hospitals: '322',
    branches_list: 'https://www.thaivivat.co.th/th/contact.php',
    number_of_branches: '27',
    agentCode: 'NA',
  },
  MSIG: {
    dealershipGarage: 'https://www.msig-thai.com/th/our-services/garage-list',
    dealershipGarageNumber: '602',
    authorizedgarage: 'https://www.msig-thai.com/th/our-services/garage-list',
    authorizedgarageNumber: '643',
    hospitals_list: 'https://www.msig-thai.com/th/our-services/hospital-list',
    number_of_hospitals: '233',
    branches_list: 'https://www.msig-thai.com/th/contact-us/branches',
    number_of_branches: '21',
    agentCode: 'ALD1769',
  },
  'Tokio Marine': {
    dealershipGarage:
      'https://www.tokiomarine.com/th/th-general/home/resources/find-a-service-provider/workshop-locator.html',
    dealershipGarageNumber: '',
    authorizedgarage:
      'https://www.tokiomarine.com/th/th-general/home/resources/find-a-service-provider/workshop-locator.html',
    authorizedgarageNumber: '',
    hospitals_list:
      'https://www.tokiomarine.com/th/th-general/home/resources/find-a-service-provider/workshop-locator.html',
    number_of_hospitals: '',
    branches_list:
      'https://www.tokiomarine.com/th/th-general/home/resources/find-a-service-provider/branch-locator.html',
    number_of_branches: '',
    agentCode: '',
  },
  'Dhipaya Insurance': {
    dealershipGarage: 'https://www.dhipaya.co.th/INSURANCE/SEARCH_OFFICIALGARAGE.ASPX?ID=0&idMenu=515',
    dealershipGarageNumber: '767',
    authorizedgarage: 'https://www.dhipaya.co.th/INSURANCE/SEARCH_GARAGE.ASPX?ID=0&idMenu=173',
    authorizedgarageNumber: '748',
    hospitals_list: 'https://www.dhipaya.co.th/INSURANCE/SEARCH_HOSPITAL.ASPX?ID=0&idMenu=85',
    number_of_hospitals: '418',
    branches_list: 'https://www.dhipaya.co.th/insurance/insurance.asp?ID=94&idMenu=79',
    number_of_branches: '32',
    agentCode: '021600075',
  },
  'Syn Mun Kong': {
    dealershipGarage: 'https://smkall.smk.co.th/cxp/webinsure/index.html#!/contact/1',
    dealershipGarageNumber: '200',
    authorizedgarage: 'https://smkall.smk.co.th/cxp/webinsure/index.html#!/contact/1',
    authorizedgarageNumber: '500',
    hospitals_list: 'https://smkall.smk.co.th/cxp/webinsure/index.html#!/contact/2',
    number_of_hospitals: '300',
    branches_list: 'https://smkall.smk.co.th/cxp/webinsure/index.html#!/contact/3',
    number_of_branches: '164',
    agentCode: '9013428',
  },
  'Muang Thai': {
    dealershipGarage: 'https://www.muangthaiinsurance.com/th/page/ServiceSearchGarage',
    dealershipGarageNumber: '276',
    authorizedgarage: 'https://www.muangthaiinsurance.com/th/page/ServiceSearchGarage',
    authorizedgarageNumber: '1231',
    hospitals_list: 'https://www.muangthaiinsurance.com/th/page/ServiceSearchGarage',
    number_of_hospitals: '323',
    branches_list: 'https://www.muangthaiinsurance.com/th/page/ServiceSearchGarage',
    number_of_branches: '22',
    agentCode: '07511790',
  },
  'Asia Insurance': {
    dealershipGarage: 'https://www.asiainsurance.co.th/garages/official-repair-shops-1/',
    dealershipGarageNumber: '200',
    authorizedgarage: 'https://www.asiainsurance.co.th/garages/contracted-repair-shops-1/',
    authorizedgarageNumber: '500',
    hospitals_list: 'https://www.asiainsurance.co.th/claim/contracted-hospital-list/',
    number_of_hospitals: '154',
    branches_list: 'https://www.asiainsurance.co.th/contact-us/',
    number_of_branches: '4',
    agentCode: 'AA30061',
  },
  'Assets Insurance': {
    dealershipGarage: '',
    dealershipGarageNumber: '',
    authorizedgarage: '',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '',
  },
  'Viriyah Insurance': {
    dealershipGarage: 'https://www.viriyah.co.th/th/contact-repair-standard.php',
    dealershipGarageNumber: '489',
    authorizedgarage: 'https://www.viriyah.co.th/th/service-center.php',
    authorizedgarageNumber: '489',
    hospitals_list: 'https://www.viriyah.co.th/th/hospital-contact-center-service-search.php',
    number_of_hospitals: '500',
    branches_list: 'https://www.viriyah.co.th/th/contact-center-service-search.php',
    number_of_branches: '138',
    agentCode: '15805',
  },
  Chubb: {
    dealershipGarage: '',
    dealershipGarageNumber: '',
    authorizedgarage: '',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '',
  },
  'Allianz Insurance': {
    dealershipGarage: '',
    dealershipGarageNumber: '',
    authorizedgarage: '',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '',
  },
  'Safety Insurance': {
    dealershipGarage: '',
    dealershipGarageNumber: '',
    authorizedgarage: '',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '',
  },
  'Thai Insurance': {
    dealershipGarage: 'https://www.thaiins.com/th/service-garages',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.thaiins.com/th/service-garages',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.thaiins.com/th/service-hospitals',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '11069',
  },
  'Indara Insurance': {
    dealershipGarage: 'http://www.indara.co.th/garagesearch.aspx',
    dealershipGarageNumber: '',
    authorizedgarage: 'http://www.indara.co.th/garagesearch.aspx',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'Indara',
  },
  'Sompo Insurance': {
    dealershipGarage: 'https://sompo.co.th/page/motor_claim',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://sompo.co.th/page/motor_claim',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'BR01213',
  },
  'Thaiset Insurance': {
    dealershipGarage: 'https://www.tsi.co.th/services/service?type=garage',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.tsi.co.th/services/service?type=garage',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'BR01213',
  },
  'Indara Insurance (Branch)': {
    dealershipGarage: 'http://www.indara.co.th/garagesearch.aspx',
    dealershipGarageNumber: '',
    authorizedgarage: 'http://www.indara.co.th/garagesearch.aspx',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'BX01-05889',
  },
  'The Union Prospers Insurance': {
    dealershipGarage: 'https://www.upp.co.th/dl/file/.pdf',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.upp.co.th/dl/file/.pdf',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'ก1000/10/4012',
  },
  'Navakij Insurance': {
    dealershipGarage: 'https://www.navakij.co.th/th/claim/pdf/1',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.navakij.co.th/th/claim/pdf/2',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.navakij.co.th/th/claim/pdf/6',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'Motor 0028 Non 0029',
  },
  'Falcon Insurance': {
    dealershipGarage: 'https://www.falconinsurance.co.th/claim-services/garage/car',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.falconinsurance.co.th/claim-services/garage/dealer',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.falconinsurance.co.th/claim-services/hospital',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '1000250',
  },
  'Krungthai Panich Insurance': {
    dealershipGarage: 'https://www.kpi.co.th/Service/SearchGarage',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.kpi.co.th/Service/SearchGarage',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.kpi.co.th/Service/SearchHospital',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: '',
    agentCode: '',
  },
  'ERGO Insurance': {
    dealershipGarage: 'https://www.ergo.co.th/garage/',
    dealershipGarageNumber: '',
    authorizedgarage: 'https://www.ergo.co.th/garage/',
    authorizedgarageNumber: '',
    hospitals_list: 'https://www.ergo.co.th/hospitals/',
    number_of_hospitals: '',
    branches_list: '',
    number_of_branches: 'https://www.ergo.co.th/branch/',
    agentCode: '4607',
  },
  'iCare Insurance': {
    dealershipGarage: '',
    dealershipGarageNumber: '',
    authorizedgarage: '',
    authorizedgarageNumber: '',
    hospitals_list: '',
    number_of_hospitals: 'https://www.icare-insurance.com/hospital',
    branches_list: '',
    number_of_branches: '',
    agentCode: 'BY0067',
  },
};

const policyStatus = {
  docs_collected: {
    text: {
      english: 'View Profile',
      thai: 'ดูประวัติ',
    },
    disabled: false,
    action: true,
    className: 'blue',
  },
  docs_pending: {
    text: {
      english: 'View Profile',
      thai: 'ดูประวัติ',
    },
    disabled: false,
    action: true,
    className: 'red',
  },
  coa_approval_pending: {
    text: {
      english: 'View Profile',
      thai: 'ดูประวัติ',
    },
    disabled: false,
    action: true,
    className: 'yellow',
  },
  docs_rejected: {
    text: {
      english: 'Update Documents',
      thai: 'อัปเดตเอกสาร',
    },
    disabled: false,
    action: true,
    className: 'red',
  },
  docs_approved: {
    text: {
      english: 'View Details',
      thai: 'ดูรายละเอียด',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  covernote_issued: {
    text: {
      english: 'Download Covernote',
      thai: 'ดาวน์โหลด Covernote',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  car_inspection_approved: {
    text: {
      english: 'View Details',
      thai: 'ดูรายละเอียด',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  car_inspection_rejected: {
    text: {
      english: 'View Details',
      thai: 'ดูรายละเอียด',
    },
    disabled: false,
    action: true,
    className: 'red',
  },
  policy_uploaded: {
    text: {
      english: 'Download Policy',
      thai: 'ดาวโหลดสำเนากรมธรรม์',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  policy_received_from_insurer: {
    text: {
      english: 'Awaiting Payment',
      thai: 'รอการชำระเงิน',
    },
    disabled: false,
    action: true,
    className: '',
  },
  policy_sent_to_user: {
    text: {
      english: 'View Tracking Details',
      thai: 'ดูรายละเอียด',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  policy_delivered_to_user: {
    text: {
      english: 'View Details',
      thai: 'ดูรายละเอียด',
    },
    disabled: false,
    action: true,
    className: 'green',
  },
  price_pending: {
    text: {
      english: 'Price Pending',
      thai: 'ยังไม่ทราบเบี้ยต่ออายุ',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  revision_pending: {
    text: {
      english: 'Revision Pending',
      thai: 'กำลังต่อรองกับบริษัทประกัน',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  price_ready: {
    text: {
      english: 'Price Ready',
      thai: 'ได้รับเบี้ยต่ออายุแล้ว',
    },
    disabled: true,
    action: false,
    className: 'green',
  },
  renewal_rejected: {
    text: {
      english: 'Renewal Rejected',
      thai: 'ปฏิเสธการต่ออายุ',
    },
    disabled: true,
    action: false,
    className: 'red',
  },
  revision_required: {
    text: {
      english: 'Revision Required',
      thai: 'กำลังต่อรองกับบริษัทประกัน',
    },
    disabled: true,
    action: false,
    className: 'red',
  },
  insurer_notified: {
    text: {
      english: 'Insurer Notifed',
      thai: '3. แจ้งงานไปที่บริษัทประกันเรียบร้อย',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  policy_issued: {
    text: {
      english: 'Policy Issued',
      thai: '4. กรมธรรม์อยู่ในระบบเรียบร้อย',
    },
    disabled: true,
    action: false,
    className: 'green',
  },
  cancellation_requested: {
    text: {
      english: 'Cancellation Requested',
      thai: 'Cancellation Requested',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  cancellation_completed: {
    text: {
      english: 'Cancellation Completed',
      thai: 'Cancellation Completed',
    },
    disabled: true,
    action: false,
    className: 'red',
  },
  cancellation_requested_insurer: {
    text: {
      english: 'Cancellation Requested Insurer',
      thai: 'Cancellation Requested Insurer',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  policy_received_by_vendor: {
    text: {
      english: 'Policy Received By Vendor',
      thai: 'Policy Received By Vendor',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  policy_sent_to_vendor: {
    text: {
      english: 'Policy Sent to Vendor',
      thai: 'Policy Sent to Vendor',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  send_policy_to_user: {
    text: {
      english: 'Send Policy to User',
      thai: 'Send Policy to User',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  sent_for_consideration_occupation: {
    text: {
      english: 'Sent For Consideration - Occupation',
      thai: 'Sent For Consideration - Occupation',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  sent_for_consideration_declaration: {
    text: {
      english: 'Sent For Consideration - Declaration',
      thai: 'Sent For Consideration - Declaration',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  insurer_rejected: {
    text: {
      english: 'Insurer Rejected',
      thai: 'Insurer Rejected',
    },
    disabled: true,
    action: false,
    className: 'red',
  },
  credit_approved: {
    text: {
      english: 'Credit Approved',
      thai: 'เครดิตอนุมัติแล้ว',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  renewal_waiting_to_issue_policy: {
    text: {
      english: 'Renewal:Waiting to Issue Policy',
      thai: '5 งานต่ออายุ: รอ ออก กธ.',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  need_to_cancel_cmi: {
    text: {
      english: 'Need to cancel CMI',
      thai: 'Need to cancel CMI',
    },
    disabled: true,
    action: false,
    className: 'yellow',
  },
  purchased: {
    className: 'green',
  },
};

const paymentStatus = {
  invoice_issued: {
    className: 'blue',
  },
  insurer_notified: {
    className: 'orange',
  },
  payment_verified: {
    className: 'green',
  },
  commission_transferred: {
    className: 'green',
  },
  transfer_note_issued: {
    className: 'green',
  },
  payment_proof_received: {
    className: 'green',
  },
  credit_time_exceeded: {
    className: 'red',
  },
  credit_approved: {
    className: 'orange',
  },
};

const insureresList = [
  'ไม่ทราบ',
  'กรุงเทพประกันภัย',
  'กรุงไทยพานิชประกันภัย',
  'คุ้มภัย',
  'เคเอสเค ประกันภัย',
  'เจพี ประกันภัย',
  'ชับบ์สามัคคีประกันภัย',
  'ทิพยประกันภัย',
  'ไทยไพบูลย์',
  'ไทยวิวัฒน์',
  'ไทยศรี',
  'ไทยเศรษฐฯ',
  'นวกิจประกันภัย',
  'บริษัทกลางฯ',
  'แปซิฟิค ครอส',
  'เมืองไทยประกันภัย',
  'วิริยะประกันภัย',
  'สินทรัพย์ประกันภัย',
  'สินมั่นคง',
  'อลิอันซ์',
  'อาคเนย์ประกันภัย',
  'อินทรประกันภัย',
  'เอเชียประกันภัย',
  'เอ็ทน่า',
  'เอ็มเอสไอจี',
  'แอกซ่าประกันภัย',
  'สินมั่นคงประกันภัย',
  'ไทยศรีประกันภัย',
  'โตเกียวมารีนประกันภัย',
  'ไทยไพบูลย์ประกันภัย',
  'แอลเอ็มจีประกันภัย',
  'ประกันภัยไทยวิวัฒน์',
  'ธนชาตประกันภัย',
  'เทเวศประกันภัย',
  'ไอโออิ',
  'นำสินประกันภัย จำกัด',
  'ไทยประกันภัย',
  'อินทรประกันภัย',
  'ซมโปะ ประกันภัย',
  'ไทยเศรษฐกิจประกันภัย',
  'อินทรประกันภัย (สาขา)',
  'สหมงคลประกันภัย',
  'นวกิจประกันภัย',
  'ฟอลคอนประกันภัย',
  'เออร์โกประกันภัย',
  'ไอแคร์ ประกันภัย',
];

const currentInsurers = [
  'กรุงเทพประกันภัย',
  'ประกันภัยไทยวิวัฒน์',
  'เอ็มเอสไอจี',
  'ธนชาตประกันภัย',
  'ทิพยประกันภัย',
  'เอเชียประกันภัย',
  'สินทรัพย์ประกันภัย',
  'วิริยะประกันภัย',
  'เมืองไทยประกันภัย',
  'สินมั่นคงประกันภัย',
  'อาคเนย์ประกันภัย',
  'เทเวศประกันภัย',
  'ไทยศรีประกันภัย',
  'โตเกียวมารีนประกันภัย',
  'ไทยไพบูลย์ประกันภัย',
  'แอกซ่าประกันภัย',
  'แอลเอ็มจีประกันภัย',
  'ไอโออิ',
  'นำสินประกันภัย จำกัด',
  'ไทยประกันภัย',
  'อินทรประกันภัย',
  'ซมโปะ ประกันภัย',
  'ไทยเศรษฐกิจประกันภัย',
  'ชับบ์สามัคคีประกันภัย',
  'อลิอันซ์',
  'เอ็ม เอส ไอ จี ประกันภัย',
  'อินทรประกันภัย (สาขา)',
  'สหมงคลประกันภัย',
  'นวกิจประกันภัย',
  'ฟอลคอนประกันภัย',
  'กรุงไทยพานิชประกันภัย',
  'เออร์โกประกันภัย',
  'ไอแคร์ ประกันภัย',
];

const currentInsurerMap = {
  กรุงเทพประกันภัย: 'Bangkok Insurance',
  ประกันภัยไทยวิวัฒน์: 'Thaivivat Insurance',
  'เอ็ม เอส ไอ จี ประกันภัย': 'MSIG',
  ธนชาตประกันภัย: 'Thanachart Insurance',
  ทิพยประกันภัย: 'Dhipaya Insurance',
  เอเชียประกันภัย: 'Asia Insurance',
  สินทรัพย์ประกันภัย: 'Assets Insurance',
  วิริยะประกันภัย: 'Viriyah Insurance',
  เมืองไทยประกันภัย: 'Muang Thai',
  สินมั่นคงประกันภัย: 'Syn Mun Kong',
  อาคเนย์ประกันภัย: 'SouthEast Insurance',
  เทเวศประกันภัย: 'Deves Insurance',
  ไทยศรีประกันภัย: 'Thaisri Insurance',
  โตเกียวมารีนประกันภัย: 'Tokio Marine',
  ไทยไพบูลย์ประกันภัย: 'Thaipaiboon Insurance',
  แอกซ่าประกันภัย: 'AXA Insurance',
  แอลเอ็มจีประกันภัย: 'LMG Insurance',
  ไอโออิ: 'AIOI',
  'นำสินประกันภัย จำกัด': 'Nam Sin Insurance',
  ไทยประกันภัย: 'Thai Insurance',
  อินทรประกันภัย: 'Indara Insurance',
  'ซมโปะ ประกันภัย': 'Sompo Insurance',
  ไทยเศรษฐกิจประกันภัย: 'Thai Setakij Insurance',
  'อินทรประกันภัย (สาขา)': 'Indara Insurance (Branch)',
  ชับบ์สามัคคีประกันภัย: 'Chubb',
  อลิอันซ์: 'Allianz Ayudhya',
  สหมงคลประกันภัย: 'The Union Prospers Insurance',
  นวกิจประกันภัย: 'Navakij Insurance',
  ฟอลคอนประกันภัย: 'Falcon Insurance',
  กรุงไทยพานิชประกันภัย: 'Krungthai Panich Insurance',
  เออร์โกประกันภัย: 'ERGO Insurance',
  'ไอแคร์ ประกันภัย': 'iCare Insurance',
};

const currentInsurerMapEng = {
  'Bangkok Insurance': 'กรุงเทพประกันภัย',
  'Thaivivat Insurance': 'ประกันภัยไทยวิวัฒน์',
  MSIG: 'เอ็ม เอส ไอ จี ประกันภัย',
  'Thanachart Insurance': 'ธนชาตประกันภัย',
  'Dhipaya Insurance': 'ทิพยประกันภัย',
  'Asia Insurance': 'เอเชียประกันภัย',
  'Assets Insurance': 'สินทรัพย์ประกันภัย',
  'Viriyah Insurance': 'วิริยะประกันภัย',
  'Muang Thai': 'เมืองไทยประกันภัย',
  'Syn Mun Kong': 'สินมั่นคงประกันภัย',
  'SouthEast Insurance': 'อาคเนย์ประกันภัย',
  'Deves Insurance': 'เทเวศประกันภัย',
  'Thaisri Insurance': 'ไทยศรีประกันภัย',
  'Tokio Marine': 'โตเกียวมารีนประกันภัย',
  'Thaipaiboon Insurance': 'ไทยไพบูลย์ประกันภัย',
  'AXA Insurance': 'แอกซ่าประกันภัย',
  'LMG Insurance': 'แอลเอ็มจีประกันภัย',
  AIOI: 'ไอโออิ',
  'Nam Sin Insurance': 'นำสินประกันภัย จำกัด',
  'Thai Insurance': 'ไทยประกันภัย',
  'Indara Insurance': 'อินทรประกันภัย',
  'Sompo Insurance': 'ซมโปะ ประกันภัย',
  'Thai Setakij Insurance': 'ไทยเศรษฐกิจประกันภัย',
  'The Union Prospers Insurance': 'สหมงคลประกันภัย',
  Chubb: 'ชับบ์สามัคคีประกันภัย',
  'Allianz Ayudhya': 'อลิอันซ์',
  'Indara Insurance (Branch)': 'อินทรประกันภัย (สาขา)',
  'Navakij Insurance': 'นวกิจประกันภัย',
  'Falcon Insurance': 'ฟอลคอนประกันภัย',
  'Krungthai Panich Insurance': 'กรุงไทยพานิชประกันภัย',
  'ERGO Insurance': 'เออร์โกประกันภัย',
  'iCare Insurance': 'ไอแคร์ ประกันภัย',
};

const label = 'ยินยอมให้แฟร์ดีเป็นผู้ดูแลเท่านั้น';

const coaMapping = {
  กรุงเทพประกันภัย: {
    imageUrl: BkiCOA,
    label: 'ยินยอมให้แฟร์ดี รหัส 651700 เป็นผู้ดูแลเท่านั้น',
    formpdf: null,
    remark: null,
  },
  โตเกียวมารีนประกันภัย: {
    imageUrl: TmthCOA,
    label: label,
    formpdf: `${S3_URL}docs/coa-forms/TMTH-FORM.pdf`,
    remark: null,
  },
  ทิพยประกันภัย: {
    imageUrl: TipCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  เทเวศประกันภัย: {
    imageUrl: DevCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  ประกันภัยไทยวิวัฒน์: {
    imageUrl: TviCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  ธนชาตประกันภัย: {
    imageUrl: TniCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  เมืองไทยประกันภัย: {
    imageUrl: MtiCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  วิริยะประกันภัย: {
    imageUrl: VibCOA,
    label: label,
    formpdf: null,
    remark:
      'กรณีเช็คเบี้ยไม่จำเป็นต้องเซ็นต์ "ยินยอมให้แฟร์ดีเป็นผู้ดูแลเท่านั้น" หาก แจ้งงานจะต้องมีการเซ็นต์ "ยินยอมให้แฟร์ดีเป็นผู้ดูแลเท่านั้น" ทุกครั้ง',
  },
  สินมั่นคงประกันภัย: {
    imageUrl: SmkCOA,
    label: label,
    formpdf: null,
    remark:
      'กรณีเช็คเบี้ยไม่จำเป็นต้องเซ็นต์ "ยินยอมให้แฟร์ดีเป็นผู้ดูแลเท่านั้น" หาก แจ้งงานจะต้องมีการเซ็นต์ "ยินยอมให้แฟร์ดีเป็นผู้ดูแลเท่านั้น" ทุกครั้ง',
  },
  อาคเนย์ประกันภัย: {
    imageUrl: SeiCOA,
    label: label,
    formpdf: `${S3_URL}docs/coa-forms/SEI-FORM.pdf`,
    remark: null,
  },
  เอเชียประกันภัย: {
    imageUrl: AsiaCOA,
    label: label,
    formpdf: null,
    remark: null,
  },
  แอกซ่าประกันภัย: {
    imageUrl: AxaCOA,
    label: label,
    formpdf: `${S3_URL}docs/coa-forms/AXA-FORM.pdf`,
    remark: null,
  },
  แอลเอ็มจีประกันภัย: {
    imageUrl: LmgCOA,
    label: label,
    formpdf: `${S3_URL}docs/coa-forms/LMG-FORM.pdf`,
    remark: null,
  },
  ไอโออิ: {
    imageUrl: AioiCOA,
    label: label,
    formPdf: null,
    remark: null,
  },
  'นำสินประกันภัย จำกัด': {
    imageUrl: NsiCOA,
    label: label,
    formpdf: `${S3_URL}docs/coa-forms/NSI-FORM.pdf`,
    remark: null,
  },
};

const fairdeeContent = {
  news: {
    videos: [
      {
        type: 'video',
        title: 'คลิปบรรยากาศงานสัมนา ขายประกันอย่างไรให้ชนะบริษัทยักษ์ใหญ่ในยุคดิจิทัล',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=AdtJeymDEv4',
        date: '2 ธันวาคม 2563',
      },
      {
        type: 'video',
        title: 'มาฟังความรู้สึกตัวแทนของFairDeeกัน!',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=BTXcfz2CJgw',
        date: '3 ธันวาคม 2563',
      },
    ],
    blogs: [
      {
        type: 'blog',
        title: 'มาแล้ว!รวมภาพบรรยากาศงานสัมมนา”จากร่วงสู่รุ่งขายประกันอย่างไร?ให้ชนะบริษัทยักษ์ใหญ่ในยุคดิจิตอล”',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/11/thumedit-01.jpg',
        link: 'https://www.fairdee.co.th/blog/2020/11/26/%e0%b8%a1%e0%b8%b2%e0%b9%81%e0%b8%a5%e0%b9%89%e0%b8%a7%e0%b8%a3%e0%b8%a7%e0%b8%a1%e0%b8%a0%e0%b8%b2%e0%b8%9e%e0%b8%9a%e0%b8%a3%e0%b8%a3%e0%b8%a2%e0%b8%b2%e0%b8%81%e0%b8%b2%e0%b8%a8%e0%b8%87%e0%b8%b2/',
        date: '26 พฤศจิกายน 2563',
      },
      {
        type: 'blog',
        title: 'แพ็คเกจ อัพ ทู ไมล์! จากทิพยประกันภัย ประกันชั้น 1 หลักพัน ไม่มีค่าเสียหายส่วนแรก',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/11/ADS06-01.jpg',
        link: 'https://www.fairdee.co.th/blog/2020/11/24/%e0%b9%81%e0%b8%9e%e0%b8%84%e0%b9%80%e0%b8%81%e0%b8%95-%e0%b8%ad%e0%b8%b1%e0%b8%9e-%e0%b8%97%e0%b8%b9-%e0%b9%84%e0%b8%a1%e0%b8%a5%e0%b9%8c-%e0%b8%88%e0%b8%b2%e0%b8%81%e0%b8%97%e0%b8%b4%e0%b8%9e/',
        date: '24 พฤศจิกายน 2563',
      },
      {
        type: 'blog',
        title: 'รู้หรือไม่ พรบ. และ ภาษี ไม่เหมือนกัน!',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2020/09/%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD%E0%B9%84%E0%B8%A1%E0%B9%88-%E0%B8%9E%E0%B8%A3%E0%B8%9A.-%E0%B9%81%E0%B8%A5%E0%B8%B0-%E0%B8%A0%E0%B8%B2%E0%B8%A9%E0%B8%B5-%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%99.png',
        link: 'https://www.fairdee.co.th/blog/2020/09/15/%e0%b8%9e%e0%b8%a3%e0%b8%9a-%e0%b9%81%e0%b8%a5%e0%b8%b0-%e0%b8%a0%e0%b8%b2%e0%b8%a9%e0%b8%b5/',
        date: '15 กันยายน 2563',
      },
      {
        type: 'blog',
        title: 'วิธีการต่ออายุ กับแฟร์ดีโบรกเกอร์ ประกันภัย จำกัด',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/09/1-1.png',
        link: 'https://www.fairdee.co.th/blog/2020/09/14/%e0%b8%a7%e0%b8%b4%e0%b8%98%e0%b8%b5%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%95%e0%b9%88%e0%b8%ad%e0%b8%ad%e0%b8%b2%e0%b8%a2%e0%b8%b8-%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b9%81%e0%b8%9f%e0%b8%a3%e0%b9%8c%e0%b8%94/',
        date: '14 กันยายน 2563',
      },
      {
        type: 'blog',
        title:
          'แพคเกตประกันชั้น 1 ใหม่จากสินมั่นคงราคาถูกมาก! เริ่มต้นที่หลักพัน ไม่มีค่าเสียหายส่วนแรก รับรถตลาดทุกกลุ่ม',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/Pricing-6.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/10/%e0%b9%81%e0%b8%9e%e0%b8%84%e0%b9%80%e0%b8%81%e0%b8%95%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%8a%e0%b8%b1%e0%b9%89%e0%b8%99-1-%e0%b9%83%e0%b8%ab%e0%b8%a1%e0%b9%88%e0%b8%88/',
        date: '10 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: 'รวมเบอร์ฉุกเฉินไว้ที่เดียว! อุ่นใจเมื่อภัยมา!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/1.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/09/%e0%b8%a3%e0%b8%a7%e0%b8%a1%e0%b9%80%e0%b8%9a%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%89%e0%b8%b8%e0%b8%81%e0%b9%80%e0%b8%89%e0%b8%b4%e0%b8%99%e0%b9%84%e0%b8%a7%e0%b9%89/',
        date: '9 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: 'คุณสามารถขายประกันอุบัติเหตุส่วนบุคคล (PA) กับแฟร์ดีได้แล้ววันนี้! มาอ่านรายละเอียดได้เลย!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/PA-insurance.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/09/%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%ad%e0%b8%b8%e0%b8%9a%e0%b8%b1%e0%b8%95%e0%b8%b4%e0%b9%80%e0%b8%ab%e0%b8%95%e0%b8%b8/',
        date: '9 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: 'คุณสามารถขายประกันยุงกับแฟร์ดีได้แล้ววันนี้! มาอ่านรายละเอียดได้เลย!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/06/Dengue-Insurance-Launch-480x402.png',
        link: 'https://www.fairdee.co.th/blog/2020/06/23/%e0%b8%84%e0%b8%b8%e0%b8%93%e0%b8%aa%e0%b8%b2%e0%b8%a1%e0%b8%b2%e0%b8%a3%e0%b8%96%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a2%e0%b8%b8%e0%b8%87%e0%b8%81/',
        date: '23 มิถุนายน 2563',
      },
      {
        type: 'blog',
        title: '5 สิ่งที่ลูกค้ามองหาในการซื้อประกันรถยนต์',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/5%E0%B8%AA%E0%B8%B4%E0%B9%88%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%A1%E0%B8%AD%E0%B8%87%E0%B8%AB%E0%B8%B2.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/5-%e0%b8%aa%e0%b8%b4%e0%b9%88%e0%b8%87%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%a5%e0%b8%b9%e0%b8%81%e0%b8%84%e0%b9%89%e0%b8%b2%e0%b8%a1%e0%b8%ad%e0%b8%87%e0%b8%ab%e0%b8%b2%e0%b9%83%e0%b8%99%e0%b8%81%e0%b8%b2/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ซื้อประกันอย่างไรให้ไม่โดนโกง',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/-e1574741400673.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/%e0%b8%8b%e0%b8%b7%e0%b9%89%e0%b8%ad%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%ad%e0%b8%a2%e0%b9%88%e0%b8%b2%e0%b8%87%e0%b9%84%e0%b8%a3%e0%b9%83%e0%b8%ab%e0%b9%89%e0%b9%84%e0%b8%a1/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'เหตุผลดีๆที่คุณควรสมัครสมาชิกกับ FairDee',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/%E0%B8%AA%E0%B8%B0%E0%B8%94%E0%B8%A7%E0%B8%81%E0%B8%94%E0%B8%B5%E0%B8%97%E0%B8%B5%E0%B9%88-FairDee.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/%e0%b9%80%e0%b8%ab%e0%b8%95%e0%b8%b8%e0%b8%9c%e0%b8%a5%e0%b8%94%e0%b8%b5%e0%b9%86%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%84%e0%b8%b8%e0%b8%93%e0%b8%84%e0%b8%a7%e0%b8%a3%e0%b8%aa%e0%b8%a1%e0%b8%b1%e0%b8%84/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'รวม 3 วิธีการเช็คอาการรถยนต์แบตเสื่อม!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/battery.jpg',
        link: 'https://www.fairdee.co.th/blog/2019/11/19/%e0%b8%a3%e0%b8%a7%e0%b8%a1-3-%e0%b8%a7%e0%b8%b4%e0%b8%98%e0%b8%b5%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%80%e0%b8%8a%e0%b9%87%e0%b8%84%e0%b8%ad%e0%b8%b2%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a3%e0%b8%96/',
        date: '19 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'คุณสามารถขายประกันรถยนต์ราคาพิเศษได้แล้ววันนี้ (ค่าคอม 7%)',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/1-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/08/%e0%b8%84%e0%b8%b8%e0%b8%93%e0%b8%aa%e0%b8%b2%e0%b8%a1%e0%b8%b2%e0%b8%a3%e0%b8%96%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a3%e0%b8%96%e0%b8%a2%e0%b8%99%e0%b8%95%e0%b9%8c%e0%b8%a3/',
        date: '8 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'สื่อการตลาดออนไลน์สำหรับตัวแทน FairDee ทุกท่าน',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2021/03/cropped-toned-image-modern-office-buildings-central-hong-kong.jpg',
        link: 'https://www.fairdee.co.th/blog/2019/10/31/%e0%b8%aa%e0%b8%b7%e0%b9%88%e0%b8%ad%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%95%e0%b8%a5%e0%b8%b2%e0%b8%94%e0%b8%ad%e0%b8%ad%e0%b8%99%e0%b9%84%e0%b8%a5%e0%b8%99%e0%b9%8c/',
        date: '31 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'นั่งท้าย กระบะคว่ำ ใครรับผิดชอบ? ประกันจะจ่ายไหมหากเมาแล้วขับ!!!!!',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/%E0%B8%A3%E0%B8%96%E0%B8%84%E0%B8%A7%E0%B9%88%E0%B8%B3-%E0%B9%83%E0%B8%84%E0%B8%A3%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%9C%E0%B8%B4%E0%B8%94%E0%B8%8A%E0%B8%AD%E0%B8%9A_-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B8%88%E0%B8%B0%E0%B8%88%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B9%84%E0%B8%AB%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%81%E0%B9%80%E0%B8%A1%E0%B8%B2%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B8%82%E0%B8%B1%E0%B8%9A-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/10/03/%e0%b8%99%e0%b8%b1%e0%b9%88%e0%b8%87%e0%b8%97%e0%b9%89%e0%b8%b2%e0%b8%a2-%e0%b8%81%e0%b8%a3%e0%b8%b0%e0%b8%9a%e0%b8%b0%e0%b8%84%e0%b8%a7%e0%b9%88%e0%b8%b3-%e0%b9%83%e0%b8%84%e0%b8%a3%e0%b8%a3%e0%b8%b1/',
        date: '3 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'กล้องติดหน้ารถยนต์ ไอเท็มเด็ด ที่ช่วยคุณบนท้องถนน',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/Copy-of-%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD%E0%B9%84%E0%B8%A1%E0%B9%88-%E0%B9%81%E0%B8%84%E0%B9%88%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%81%E0%B8%A5%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%81%E0%B9%87%E0%B8%A5%E0%B8%94%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/30/%e0%b8%81%e0%b8%a5%e0%b9%89%e0%b8%ad%e0%b8%87%e0%b8%95%e0%b8%b4%e0%b8%94%e0%b8%ab%e0%b8%99%e0%b9%89%e0%b8%b2%e0%b8%a3%e0%b8%96%e0%b8%a2%e0%b8%99%e0%b8%95%e0%b9%8c-%e0%b9%84%e0%b8%ad%e0%b9%80%e0%b8%97/',
        date: '30 กันยายน 2562',
      },
      {
        type: 'blog',
        title: 'ผู้ร่วมก่อตั้ง และกรรมการจัดการของบริษัทแฟร์ดี',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/Thana.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/24/%e0%b9%81%e0%b8%9f%e0%b8%a3%e0%b9%8c%e0%b8%94%e0%b8%b5%e0%b8%97%e0%b8%b5%e0%b8%a1%e0%b9%80%e0%b8%94%e0%b8%a2%e0%b9%8c/',
        date: '24 กันยายน 2562',
      },
    ],
  },
  clubFairdee: {
    videos: [
      {
        type: 'video',
        title: 'EP1 Club FairDee พี่อ๋องมาฉอด : 10 คำถามยอดฮิตของตัวแทนเกี่ยวการทำงานกับFairDee',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=cf2IBciFZ0Q',
        date: '11 ธันวาคม 2563',
      },
      {
        type: 'video',
        title: 'EP2 Club FairDee พี่อ๋องมาฉอด : 10 คำถามยอดฮิตของตัวแทนเกี่ยวการทำงานกับFairDee',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=VhkVYVANODI',
        date: '24 ธันวาคม 2563',
      },
      {
        type: 'video',
        title: 'สอนวิธีใช้เว็บไซต์ FairDee',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=2yXUXYPWswY',
        date: '4 มกราคม 2563',
      },
      {
        type: 'video',
        title: 'FairDee จะช่วยให้การขายประกันรถยนต์ของคุณได้อย่างไร',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=ShLpnmsp7KU',
        date: '7 กุมภาพันธ์ 2563',
      },
      {
        type: 'video',
        title: 'การตาม lead แบบ call center',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=0AwsWav48GU',
        date: '9 มกราคม 2563',
      },
    ],
    blogs: [],
  },
  insuranceKnowledge: {
    videos: [
      {
        type: 'video',
        title: 'วิธีการปิดการขายด้วยข้อมูลรถของลูกค้า ตอนที่ 1',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=2yXUXYPWswY',
        date: '4 มกราคม 2563',
      },
      {
        type: 'video',
        title: 'วิธีการปิดการขายด้วยข้อมูลรถของลูกค้า ตอนที่ 2',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=ShLpnmsp7KU',
        date: '7 กุมภาพันธ์ 2563',
      },
      {
        type: 'video',
        title: 'วิธีการปิดการขายด้วยข้อมูลรถของลูกค้า ตอนที่ 3',
        text: '',
        image: '',
        link: 'https://www.youtube.com/watch?v=0AwsWav48GU',
        date: '9 มกราคม 2563',
      },
    ],
    blogs: [
      {
        type: 'blog',
        title: 'รวมเบอร์ฉุกเฉินไว้ที่เดียว! อุ่นใจเมื่อภัยมา!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/1.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/09/%e0%b8%a3%e0%b8%a7%e0%b8%a1%e0%b9%80%e0%b8%9a%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%89%e0%b8%b8%e0%b8%81%e0%b9%80%e0%b8%89%e0%b8%b4%e0%b8%99%e0%b9%84%e0%b8%a7%e0%b9%89/',
        date: '09 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: '6 สิ่งที่นักขายประกันที่ดีเขาทำกัน',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/6%E0%B8%AA%E0%B8%B4%E0%B9%88%E0%B8%87%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B8%97%E0%B8%B3.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/6-%e0%b8%aa%e0%b8%b4%e0%b9%88%e0%b8%87%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%99%e0%b8%b1%e0%b8%81%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%97%e0%b8%b5%e0%b9%88/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ทำยังไงจะขายประกันได้มากขึ้น?',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/%E0%B8%97%E0%B8%B3%E0%B9%84%E0%B8%87%E0%B8%88%E0%B8%B0%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%A1%E0%B8%B2%E0%B8%81%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/%e0%b8%97%e0%b8%b3%e0%b8%a2%e0%b8%b1%e0%b8%87%e0%b9%84%e0%b8%87%e0%b8%88%e0%b8%b0%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b9%84%e0%b8%94%e0%b9%89%e0%b8%a1/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: '5 สิ่งที่ต้องรู้ในการทำ Online Marketing ให้ประสบความสำเร็จ',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/5-%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%A5%E0%B8%B2%E0%B8%94.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/5-%e0%b8%aa%e0%b8%b4%e0%b9%88%e0%b8%87%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%87%e0%b8%a3%e0%b8%b9%e0%b9%89%e0%b9%83%e0%b8%99%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%97%e0%b8%b3-online-m/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ประกันวินาศภัยคืออะไรกันแน่?',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/-e1574741307989.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/26/%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a7%e0%b8%b4%e0%b8%99%e0%b8%b2%e0%b8%a8%e0%b8%a0%e0%b8%b1%e0%b8%a2%e0%b8%84%e0%b8%b7%e0%b8%ad%e0%b8%ad%e0%b8%b0%e0%b9%84%e0%b8%a3%e0%b8%81/',
        date: '26 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'การสอบบัตรนายหน้า',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2021/03/cropped-toned-image-modern-office-buildings-central-hong-kong.jpg',
        link: 'https://www.fairdee.co.th/blog/2019/11/19/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b8%ad%e0%b8%9a%e0%b8%9a%e0%b8%b1%e0%b8%95%e0%b8%a3%e0%b8%99%e0%b8%b2%e0%b8%a2%e0%b8%ab%e0%b8%99%e0%b9%89%e0%b8%b2/',
        date: '19 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ตัวแทนมือใหม่ เริ่มต้นหาลูกค้ายังไงดี?',
        text: '',
        image: '',
        link: 'https://www.fairdee.co.th/blog/2019/11/19/%e0%b8%95%e0%b8%b1%e0%b8%a7%e0%b9%81%e0%b8%97%e0%b8%99%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%83%e0%b8%ab%e0%b8%a1%e0%b9%88-%e0%b9%80%e0%b8%a3%e0%b8%b4%e0%b9%88%e0%b8%a1%e0%b8%95%e0%b9%89%e0%b8%99%e0%b8%ab/',
        date: '19 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'มาทำความรู้จักความแตกต่างของประกันแต่ละประเภทและพรบ.กัน!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/image4-1.jpg',
        link: 'https://www.fairdee.co.th/blog/2019/11/19/%e0%b8%a1%e0%b8%b2%e0%b8%97%e0%b8%b3%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b8%a3%e0%b8%b9%e0%b9%89%e0%b8%88%e0%b8%b1%e0%b8%81%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b9%81%e0%b8%95%e0%b8%81%e0%b8%95/',
        date: '19 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ค่าประกันรถยนต์ ถูก-แพง ขึ้นอยู่กับอะไร?',
        text: '',
        image: '',
        link: 'https://www.fairdee.co.th/blog/2019/10/31/%e0%b8%84%e0%b9%88%e0%b8%b2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a3%e0%b8%96%e0%b8%a2%e0%b8%99%e0%b8%95%e0%b9%8c%e0%b8%96%e0%b8%b9%e0%b8%81-%e0%b9%81%e0%b8%9e%e0%b8%87/',
        date: '31 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'ปิดการขายได้มากขึ้น! พบกับวิธีการคุยกับลูกค้า 5 ประเภท',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/Fiction-vs-Non-Fiction-4-.png',
        link: 'https://www.fairdee.co.th/blog/2019/10/30/%e0%b8%9b%e0%b8%b4%e0%b8%94%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b9%84%e0%b8%94%e0%b9%89%e0%b8%a1%e0%b8%b2%e0%b8%81%e0%b8%82%e0%b8%b6%e0%b9%89%e0%b8%99-%e0%b8%9e%e0%b8%9a%e0%b8%81/',
        date: '30 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'ค่าเสียหายส่วนแรก (Deductible) คืออะไร เข้าใจง่ายๆใน 5 นาที',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B9%80%E0%B8%AA%E0%B8%B5%E0%B8%A2%E0%B8%AB%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B9%81%E0%B8%A3%E0%B8%81-Deductible-%E0%B8%84%E0%B8%B7%E0%B8%AD%E0%B8%AD%E0%B8%B0%E0%B9%84%E0%B8%A3-%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B9%83%E0%B8%88%E0%B8%87%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B9%86%E0%B9%83%E0%B8%99-5-%E0%B8%99%E0%B8%B2%E0%B8%97%E0%B8%B5.png',
        link: 'https://www.fairdee.co.th/blog/2019/10/03/%e0%b8%84%e0%b9%88%e0%b8%b2%e0%b9%80%e0%b8%aa%e0%b8%b5%e0%b8%a2%e0%b8%ab%e0%b8%b2%e0%b8%a2%e0%b8%aa%e0%b9%88%e0%b8%a7%e0%b8%99%e0%b9%81%e0%b8%a3%e0%b8%81-deductible-%e0%b8%84%e0%b8%b7%e0%b8%ad/',
        date: '03 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'น้ำท่วม ประกัน รับผิดชอบไหม?',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/Copy-of-%E0%B8%99%E0%B9%89%E0%B8%B3%E0%B8%97%E0%B9%88%E0%B8%A7%E0%B8%A1-%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%99-%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%9C%E0%B8%B4%E0%B8%94%E0%B8%8A%E0%B8%AD%E0%B8%9A%E0%B9%84%E0%B8%AB%E0%B8%A1_.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/30/%e0%b8%99%e0%b9%89%e0%b8%b3%e0%b8%97%e0%b9%88%e0%b8%a7%e0%b8%a1-%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99-%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b8%9c%e0%b8%b4%e0%b8%94%e0%b8%8a%e0%b8%ad%e0%b8%9a/',
        date: '13 กันยายน 2562',
      },
      {
        type: 'blog',
        title: 'เลือกประกันที่เหมาะ และโดนใจ ต้องพิจารณาจากอะไรบ้าง?',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/Copy-of-%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99-%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%84%E0%B8%B8%E0%B8%93-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/30/%e0%b9%80%e0%b8%a5%e0%b8%b7%e0%b8%ad%e0%b8%81%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b9%80%e0%b8%ab%e0%b8%a1%e0%b8%b2%e0%b8%b0-%e0%b9%81%e0%b8%a5%e0%b8%b0/',
        date: '30 กันยายน 2562',
      },
      {
        type: 'blog',
        title: 'เกิดอุบัติเหตุ ไม่ต้องตกใจ ทริคการเคลมแสนง่าย ที่คุณควรรู้',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/claim.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/30/%e0%b9%80%e0%b8%81%e0%b8%b4%e0%b8%94%e0%b8%ad%e0%b8%b8%e0%b8%9a%e0%b8%b1%e0%b8%95%e0%b8%b4%e0%b9%80%e0%b8%ab%e0%b8%95%e0%b8%b8-%e0%b9%84%e0%b8%a1%e0%b9%88%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%87%e0%b8%95/',
        date: '30 กันยายน 2562',
      },
      {
        type: 'blog',
        title: 'ประกันรถ ไม่ใช่เรื่องยากอีกต่อไป ศัพท์ประกันรถเบื้องต้นง่ายๆ ที่ควรรู้',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/09/1-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/09/30/%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a3%e0%b8%96-%e0%b9%84%e0%b8%a1%e0%b9%88%e0%b9%83%e0%b8%8a%e0%b9%88%e0%b9%80%e0%b8%a3%e0%b8%b7%e0%b9%88%e0%b8%ad%e0%b8%87%e0%b8%a2%e0%b8%b2/',
        date: '13 กันยายน 2562',
      },
    ],
  },
  packages: {
    videos: [],
    blogs: [
      {
        type: 'blog',
        title:
          'แพคเกตประกันชั้น 1 ใหม่จากสินมั่นคงราคาถูกมาก! เริ่มต้นที่หลักพัน ไม่มีค่าเสียหายส่วนแรก รับรถตลาดทุกกลุ่ม',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/Pricing-6.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/10/%e0%b9%81%e0%b8%9e%e0%b8%84%e0%b9%80%e0%b8%81%e0%b8%95%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%8a%e0%b8%b1%e0%b9%89%e0%b8%99-1-%e0%b9%83%e0%b8%ab%e0%b8%a1%e0%b9%88%e0%b8%88/',
        date: '10 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: 'ยินดีต้อนรับ 2 บริษัทประกันใหม่ Chubb และ Aillianz',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2020/07/%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A8%E0%B8%AD%E0%B8%B1%E0%B8%9B%E0%B9%80%E0%B8%94%E0%B8%95%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B8%84%E0%B8%AD%E0%B8%A1-2.png',
        link: 'https://www.fairdee.co.th/blog/2020/07/02/1415/',
        date: '02 กรกฎาคม 2563',
      },
      {
        type: 'blog',
        title: 'อัพเดตพาร์ทเนอร์ทั้ง 14 บริษัทกับ FairDee',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2020/01/2020.png',
        link: 'https://www.fairdee.co.th/blog/2020/01/23/%e0%b8%ad%e0%b8%b1%e0%b8%9e%e0%b9%80%e0%b8%94%e0%b8%95%e0%b8%9e%e0%b8%b2%e0%b8%a3%e0%b9%8c%e0%b8%97%e0%b9%80%e0%b8%99%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%97%e0%b8%b1%e0%b9%89%e0%b8%87-14-%e0%b8%9a/',
        date: '23 มกราคม 2563',
      },
      {
        type: 'blog',
        title: 'ยินดีต้อนรับ เทเวศประกันภัย!',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/%E0%B9%80%E0%B8%8A%E0%B9%87%E0%B8%84%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B9%89%E0%B8%A2%E0%B9%81%E0%B8%88%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-6.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/27/%e0%b8%a2%e0%b8%b4%e0%b8%99%e0%b8%94%e0%b8%b5%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%99%e0%b8%a3%e0%b8%b1%e0%b8%9a-%e0%b9%80%e0%b8%97%e0%b9%80%e0%b8%a7%e0%b8%a8%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1/',
        date: '13 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ยินดีต้อนรับ อาคเนย์ประกันภัย!',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/%E0%B9%80%E0%B8%8A%E0%B9%87%E0%B8%84%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B9%89%E0%B8%A2%E0%B9%81%E0%B8%88%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-4.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/13/%e0%b8%a2%e0%b8%b4%e0%b8%99%e0%b8%94%e0%b8%b5%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%99%e0%b8%a3%e0%b8%b1%e0%b8%9a-%e0%b8%ad%e0%b8%b2%e0%b8%84%e0%b9%80%e0%b8%99%e0%b8%a2%e0%b9%8c%e0%b8%9b%e0%b8%a3%e0%b8%b0/',
        date: '13 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ยินดีต้อนรับบริษัท เอเชียประกันภัย 1950 จำกัด',
        text: '',
        image:
          'https://www.fairdee.co.th/blog/wp-content/uploads/2019/11/%E0%B9%80%E0%B8%8A%E0%B9%87%E0%B8%84%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B9%89%E0%B8%A2%E0%B9%81%E0%B8%88%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7-3.png',
        link: 'https://www.fairdee.co.th/blog/2019/11/08/%e0%b8%a2%e0%b8%b4%e0%b8%99%e0%b8%94%e0%b8%b5%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%99%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b8%9a%e0%b8%a3%e0%b8%b4%e0%b8%a9%e0%b8%b1%e0%b8%97-%e0%b9%80%e0%b8%ad%e0%b9%80%e0%b8%8a/',
        date: '08 พฤศจิกายน 2562',
      },
      {
        type: 'blog',
        title: 'ยินดีต้อนรับไทยวิวัฒน์ประกันภัย',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/11.png',
        link: 'https://www.fairdee.co.th/blog/2019/10/18/%e0%b8%a2%e0%b8%b4%e0%b8%99%e0%b8%94%e0%b8%b5%e0%b8%95%e0%b9%89%e0%b8%ad%e0%b8%99%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b9%84%e0%b8%97%e0%b8%a2%e0%b8%a7%e0%b8%b4%e0%b8%a7%e0%b8%b1%e0%b8%92%e0%b8%99%e0%b9%8c/',
        date: '18 ตุลาคม 2562',
      },
      {
        type: 'blog',
        title: 'ขายประกันธนชาตประกันภัยได้แล้ว!',
        text: '',
        image: 'https://www.fairdee.co.th/blog/wp-content/uploads/2019/10/2-1.png',
        link: 'https://www.fairdee.co.th/blog/2019/10/15/%e0%b8%82%e0%b8%b2%e0%b8%a2%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%98%e0%b8%99%e0%b8%8a%e0%b8%b2%e0%b8%95%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%b1%e0%b8%99%e0%b8%a0%e0%b8%b1/',
        date: '15 ตุลาคม 2562',
      },
    ],
  },
};

const quotationQueryStatus = {
  quotation_shared_with_agent: {
    text: {
      english: 'Quotation Shared With Agent',
      thai: 'Quotation Shared With Agent',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: 'quotation shared with agent',
  },
  one_followup_with_insurer: {
    text: {
      english: '1 Follow Up With Insurer',
      thai: '1 Follow Up With Insurer',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: '1 followup with insurer',
  },
  two_followup_with_insurer: {
    text: {
      english: '2 Follow Up With Insurer',
      thai: '2 Follow Up With Insurer',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: '2 followup with insurer',
  },
  one_followup_with_agent: {
    text: {
      english: '1 Follow Up With Agent',
      thai: '1 Follow Up With Agent',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: '1 followup with agent',
  },
  two_followup_with_agent: {
    text: {
      english: '2 Follow Up With Agent',
      thai: '2 Follow Up With Agent',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: '2 followup with agent',
  },
  could_not_convert_lead: {
    text: {
      english: 'Could Not Convert',
      thai: 'Could Not Convert',
    },
    disabled: false,
    action: true,
    className: 'blue',
    value: 'could not convert',
  },
};

const PHOTO_INSPECTION_URL_MAP = {
  all: `${S3_URL}docs/self_inspection_conditions_all_v2.pdf`,
};

const PHOTO_INSPECTION_INSURER_LISTING = [
  'msig',
  'tip',
  'vib',
  'tpb',
  'lmg',
  'chub',
  'azay',
  'aioi',
  'upp',
  'smk',
  'tvi',
  'mti',
  'kpi',
  'tni',
  'indara2',
];

const PHOTO_INSPECTION_LIMIT_INSURER_MAP = {
  msig: 9,
  tip: 9,
  vib: 9,
  tpb: 9,
  lmg: 5,
  chub: 9,
  azay: 12,
  aioi: 12,
  upp: 9,
  smk: 1,
  tvi: 1,
  indara2: 8,
};

const ISSUELIST = [
  'Typo (Customer Details, Address, Vehicle Plate, Chassis Number)',
  'Incorrect Car Selection (Make, Model, Sub model, Year, Car code, Province)',
  'Incorrect Package Selection',
  'Incorrect Document Upload',
  'Car Inspection',
  'KYC',
  'Sum Insured',
  'Corporate Customer Document',
  'EV Car Document',
  'Missing proof of name changing',
  'Missing proof of selling a car',
  'Additional COA is required',
  'Incorrect COA Document',
  'Incorrect Sale Report Channel (Correct flow is system stored flow)',
  'Incorrect Sale Report Channel (Correct flow is COA)',
  'File size is more than 25 MB',
  'Payment Slip Issue',
  'Other Finance Issues',
  'Others',
];

const THAI_REJECTION_REASONS = {
  'บริษัทประกันปฏิเสธ (ได้รับเบี้ยคืนเต็มจำนวน)': 'Insurer rejected (full refund)',
  'ลูกค้าประสงค์ขอยกเลิก บริษัทประกันยังไม่ได้ออกกรมธรรม์ (ได้รับเบี้ยคืนเต็มจำนวน)':
    'Customer canceled policy not issued by insurer (full refunded)',
  'ลูกค้าประสงค์ขอยกเลิก บริษัทประกันออกกรมธรรม์เรียบร้อย (ได้รับเบี้ยคืนตามอัตรา short-rate)':
    'Customer canceled policy after issued insurer (short-rate)',
  'ลูกค้าประสงค์ขอยกเลิก บริษัทประกันออกกรมธรรม์เรียบร้อย (ได้รับเบี้ยคืนเต็มจำนวน)':
    'Customer canceled policy after issued insurer (full refund)',
  'ลูกค้าผิดนัดชำระ/ค้างชำระเกินกำหนด (ได้รับเบี้ยคืนตามอัตรา short-rate)':
    'Customer failed re-payment/Overdue (short-rate)',
  'ลูกค้าผิดนัดชำระ/ค้างชำระเกินกำหนด': 'Customer Failed Re-Payment/Overdue',
  'ลูกค้าขายรถ/ถูกไฟแนนซ์ยึดรถ (ได้รับเบี้ยคืนตามอัตรา short-rate)':
    'Customer canceled, sold their car/ finance (short-rate)',
  'เบี้ย/ทุนกรมธรรม์มีการเปลี่ยนแปลง/ไม่ถูกต้อง บริษัทประกันยังไม่ได้ออกกรมธรรม์ (ได้รับเบี้ยคืนเต็มจำนวน)':
    'Issue with premium/sum insured policy not issued by insurer (full refund)',
  'เบี้ย/ทุนกรมธรรม์มีการเปลี่ยนแปลง/ไม่ถูกต้อง บริษัทประกันออกกรมธรรม์เรียบร้อย (ได้รับเบี้ยคืนตามอัตรา short-rate)':
    'Issue with premium/sum insured policy already issued by insurer (short-rate)',
  'เจ้าหน้าที่คีย์ข้อมูลไม่ถูกต้อง ทำการสลักหลัง (ไม่มีการยกเลิก)':
    'Incorrectly inputted information and customer requested endorsement (no cancellation)',
  'เจ้าหน้าที่คีย์ข้อมูลไม่ถูกต้อง บริษัทประกันออกกรมธรรม์เรียบร้อย (ได้รับเบี้ยคืนตามอัตรา short-rate)':
    'Incorrectly inputted information and customer requested cancellation (short-rate)',
  'เจ้าหน้าที่คีย์ข้อมูลไม่ถูกต้อง บริษัทประกันยังไม่ได้ออกกรมธรรม์ (ได้รับเบี้ยคืนเต็มจำนวน)':
    'Incorrectly inputted information and customer requested cancellation (full refund)',
  'เจ้าหน้าที่คีย์ข้อมูลไม่ถูกต้อง บริษัทประกันยังไม่ได้ออกกรมธรรม์ (ได้รับเบี้ยคืนเต็มจำนวน)':
    'Incorrectly inputted information and customer requested cancellation and the policy has not been issued by the insurer (full refund)',
  ตัวแทนคีย์ข้อมูลไม่ถูกต้อง: 'Agent input wrong information',
  ตัวแทนแนบเอกสารไม่ถูกต้อง: 'Agent provide incomplete documents',
  ตัวแทนแจ้งงานที่ไม่รองรับผ่านเบี้ยคีย์มือ: 'Agent report special packages',
  ตัวแทนแจ้งงานเบี้ยเพ็คแกต: 'Agent report system  packages',
  'อื่นๆ (ไม่มีอีเมลส่งถึงบ.ประกัน ใช้สำหรับภายในเท่านั้น)':
    'Other (No email will be sent to insurers, Internal submission)',
};

export const SAMPLE_WHT_FILE_CSV = `${S3_URL}trial_wht.xlsx`;
export const SAMPLE_GAMIFICATION_REDEMPTION_CSV = `${S3_URL}gamification/bulkredemptiontemplate.csv`;

export {
  dropdownYears,
  monthsDropDown,
  insurersMap,
  filterMap,
  garageMap,
  sortMap,
  defaultFilterData,
  banks,
  genders,
  sortMapEnglish,
  driverAgeGroup,
  insurerInfoMap,
  policyStatus,
  insureresList,
  currentInsurers,
  currentInsurerMap,
  currentInsurerMapEng,
  coaMapping,
  fairdeeContent,
  quotationQueryStatus,
  paymentStatus,
  policyStatusOptions,
  paymentStatusOptions,
  PHOTO_INSPECTION_URL_MAP,
  PHOTO_INSPECTION_INSURER_LISTING,
  PHOTO_INSPECTION_LIMIT_INSURER_MAP,
  THAI_REJECTION_REASONS,
  ISSUELIST,
};
