import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import api from '../../commonjs/api';
import { paymentStatus, policyStatus } from '../../commonjs/commonData';
import CrossIcon from '../../images/fairdee-x-plus/icons/cross-in-circle.svg';
import SearchIcon from '../../images/fairdee-x-plus/icons/search.svg';
import { errorHandler, snakeToTitleCase } from '../../utils/helper';
import './_global-search.scss';

const IGNORE_KEYS = ['doc_created_at', 'wizard_url', 'document_type', 'highlight'],
  TAGS_KEYS = ['payment_status', 'policy_status'],
  PAYMENT_POLICY_STATUS: any = { ...policyStatus, ...paymentStatus };

const AgentCard: React.FC<{ data: any; action: any }> = ({ data, action }) => {
  return (
    <div className="agent-card">
      <div className="card-wrap">
        <div className="img-wrap">
          <UserOutlined />
        </div>
        <div className="content-wrap">
          <div className="text-wrap">
            <p className="text-one">{data.agent_full_name}</p>
            <p className="link-text" onClick={() => action(data.wizard_url)}>
              View Details
            </p>
          </div>
          <p className="line"></p>
          {Object.keys(data)
            .filter((key) => !IGNORE_KEYS.includes(key))
            .map((key: string, index: number) => (
              <div className="key-value-wrap" key={index}>
                <p className="key">{snakeToTitleCase(key)}</p>
                <p
                  className={
                    'value ' +
                    (TAGS_KEYS.includes(key) && data[key] ? `tag ${PAYMENT_POLICY_STATUS[data[key]]?.className}` : '')
                  }
                  dangerouslySetInnerHTML={{
                    __html: snakeToTitleCase(data['highlight']?.[key]?.[0] || data[key] || '-'),
                  }}
                ></p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const GlobalSearch: React.FC = () => {
  const [showSearch, setShowSearch] = useState(false),
    [search, setSearch] = useState(''),
    [searchList, setSearchList] = useState<any>({}),
    [loading, setLoading] = useState(false),
    [noData, setNoData] = useState(false);

  function vehicleNumberSearch(search: string) {
    if (!search) {
      setSearchList({});
      return;
    }
    setLoading(true);
    api.utils
      .vehicleNumberSearch({ vehicle_number: search })
      .then((resp) => {
        if (!resp.length) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        let obj: any = {};
        resp.forEach((data: any) => {
          if (!obj[data.document_type]) {
            obj[data.document_type] = [];
          }
          obj[data.document_type].push(data);
        });
        setSearchList(obj);
        setLoading(false);
      })
      .catch((error: any) => {
        errorHandler(error.response, true);
        setLoading(false);
      });
  }

  function handleChange(e: any) {
    const value = e.target.value;
    setSearch(value);
    vehicleNumberSearch(value);
  }

  function navigate(link: string) {
    window.open(link, '_blank');
  }

  return (
    <div className="global-search">
      <div className="input-wrap" onClick={() => setShowSearch(true)}>
        <div className="input-field">Search</div>
      </div>
      {showSearch ? (
        <div className="search-wrap">
          <div className="content-wrap">
            <p className="text">Search on Fairdee</p>

            <img src={CrossIcon} alt="icon" className="cross" onClick={() => setShowSearch(false)} />

            <div className="input-element">
              <img src={SearchIcon} alt="" className="search" />
              <DebounceInput
                minLength={0}
                debounceTimeout={600}
                type="text"
                placeholder="Search"
                autoFocus={true}
                value={search}
                onChange={handleChange}
              />
              {search ? (
                <p
                  className="clear-text"
                  onClick={() => {
                    setSearch('');
                    setSearchList([]);
                  }}
                >
                  Clear
                </p>
              ) : null}
            </div>

            <div className="search-results">
              {loading ? (
                <div className="loading-wrap">
                  <LoadingOutlined />
                </div>
              ) : null}
              {Object.keys(searchList).length ? (
                Object.keys(searchList).map((key) => {
                  return (
                    <div className={'column ' + key} key={key}>
                      <p className="title-text">
                        {key === 'qoala_sub_order' ? 'Platform Orders' : snakeToTitleCase(key)}
                      </p>
                      <div className="grid-wrap">
                        {searchList[key].map((list: any, index: number) => {
                          if (key === 'affiliate') {
                            return <AgentCard data={list} action={navigate} />;
                          }
                          return (
                            <div className="result-wrap" key={index}>
                              <p className="link-text" onClick={() => navigate(list.wizard_url)}>
                                View Details
                              </p>
                              {Object.keys(list)
                                .filter((key) => !IGNORE_KEYS.includes(key))
                                .map((key: string, index: number) => (
                                  <div className="key-value-wrap" key={index}>
                                    <p className="key">{snakeToTitleCase(key)}</p>
                                    <p
                                      className={
                                        'value ' +
                                        (TAGS_KEYS.includes(key) && list[key]
                                          ? `tag ${PAYMENT_POLICY_STATUS[list[key]]?.className}`
                                          : '')
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: snakeToTitleCase(list['highlight']?.[key]?.[0] || list[key] || '-'),
                                      }}
                                    ></p>
                                  </div>
                                ))}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : noData ? (
                <div className="no-data">We dont have any data for the query</div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GlobalSearch;
